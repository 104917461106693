import React from 'react';
import { IconStyled } from './styles/icon.style';

interface IconProps {
    name: string,
    className?: string,
    size?: 'small' | 'large',
    strokeWidth?: number,
}


const Icon = IconStyled(function (props: IconProps) {
    const { name, className, size = '' } = props;
    // @ts-ignore
    return <ion-icon name={name} class={className} size={size}></ion-icon>;
});

export default Icon;
