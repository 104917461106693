/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from 'react';
import Button from '@atlaskit/button';
import { DeleteTwoTone, SaveOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Modal } from 'antd';
import { TablePreference, UserPreferences } from 'core/entities';
import { ColumnConfig, DynamicForm, showConfirm } from 'components';
import { connect } from 'react-redux';
import appActions from 'redux/app/actions';
import { MenuItem } from '../DynamicTable.style';
import './ViewSelector.css';

const { addUserTablePreference, editUserTablePreference, deleteUserTablePreference } = appActions;

interface Props {
  tableViewChanged: boolean
  onViewLoaded: (columns: TablePreference) => void
  currentColumns: string
  tableName: string
  userPreferences?: UserPreferences
  originalColumns: ColumnConfig[]
}

interface DispatchProps extends Props {
  addUserTablePreference: typeof addUserTablePreference
  editUserTablePreference: typeof editUserTablePreference
  deleteUserTablePreference: typeof deleteUserTablePreference
}

interface State {
  visible: boolean,
  selectedPreference?: TablePreference | null,
  confirmVisible: boolean
  dropdownVisible: boolean
}

class ViewSelectorConnected extends Component<DispatchProps, State> {
  state: State = {
    visible: false,
    confirmVisible: false,
    dropdownVisible: false,
  };

  componentDidMount(): void {
    const { tableName, userPreferences } = this.props;
    if (userPreferences) {
      const selected = (userPreferences.tablePreferences[tableName] || []).find(value => value.isSelected);
      if (selected) {
        this.setState({ selectedPreference: selected });
        this.props.onViewLoaded(selected);
      }
    }
  }

  handleMenuClick = (e: any) => {
    if (e.key === '-1') {
      this.setState({ visible: true });

    } else if (e.key === '-2') {
      const preference = this.props.originalColumns.map(config => config.key);

      this.props.onViewLoaded({
        checked_tabs: preference.join(','),
        table_name: '',
        preference_name: '',
      });

      this.setState({ selectedPreference: null });
    } else {
      const { tableName, userPreferences } = this.props;

      if (!userPreferences) return;

      const tablePreference = userPreferences.tablePreferences[tableName];
      const selectedPreference = tablePreference.find((element: TablePreference) => element.preference_name === e.key);

      if (!selectedPreference) return;

      this.props.editUserTablePreference(selectedPreference);
      this.props.onViewLoaded(selectedPreference);
      this.setState({ selectedPreference });
    }
  };

  handleVisibleChange = (visible: boolean) => {
    this.setState({ confirmVisible: visible });
  };

  getMenuItems = () => {
    const { tableName, userPreferences } = this.props;

    if (!userPreferences) {
      return null;
    }

    const onClick = (preference: TablePreference) => {
      this.props.deleteUserTablePreference(preference);

      const pref = this.state.selectedPreference;

      if (pref && pref.preference_name === preference.preference_name)
        this.setState({ selectedPreference: null });
    };

    const tablePreferences = userPreferences.tablePreferences[tableName] || [];
    const DeleteIcon = (props: { handleClick: Function }) =>
      <DeleteTwoTone
        className="menu-item-icon"
        twoToneColor="#eb2f96"
        onClick={() => {
          showConfirm(props.handleClick, 'View');
        }} />;
    /**
     * added a ts ignore cause we transform the array into a record when reading from localstorage
     */
    return Object.keys(tablePreferences).map((key: string) => {
      // @ts-ignore
      const currentPreference = tablePreferences[key];
      const { preference_name } = currentPreference;

      return <Menu.Item
        key={preference_name}>
        <MenuItem>
          {preference_name}
          <DeleteIcon handleClick={() => onClick(currentPreference)}/>
        </MenuItem>
      </Menu.Item>;
    });
  };

  handleSubmit = (data: any) => {
    const { tableName, currentColumns } = this.props;
    const preference = new TablePreference({
      ...data,
      table_name: tableName,
      checked_tabs: currentColumns,
      isSelected: true,
    });

    this.setState({ visible: false, selectedPreference: preference });

    this.props.addUserTablePreference(preference);
  };

  getModal = () => (
    <Modal
      bodyStyle={{ minHeight: '150px' }}
      footer={null}
      title="Create View"
      visible={this.state.visible}
      onCancel={this.handleCancel}
      destroyOnClose
    >
      <DynamicForm
        classConstructor={TablePreference}
        fields={TablePreference.FORM_FIELDS}
        onSubmit={this.handleSubmit}
        endpoint=""
        showContinueEditing={false}
      />
    </Modal>
  );

  getMenu = () => (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="-1" disabled={!this.props.tableViewChanged}>
        Create View
      </Menu.Item>
      <Menu.Item key="-2">
        Default View
      </Menu.Item>
      <Menu.Divider key="divider 1"/>
      <Menu.Item key="-3" disabled>
        My Views
      </Menu.Item>
      <Menu.Divider key="divider 2"/>
      {this.getMenuItems()}
    </Menu>
  );

  saveSelectedPreference = () => {
    if (!this.state.selectedPreference) return;

    const { tableName, currentColumns, userPreferences } = this.props;
    const { preference_name } = this.state.selectedPreference;

    if (!userPreferences) return;

    const newPreferences = new TablePreference({
      preference_name,
      table_name: tableName,
      checked_tabs: currentColumns,
      isSelected: true,
    });

    this.setState({ selectedPreference: newPreferences });
    this.props.editUserTablePreference(newPreferences);
  };

  handleCancel = () => this.setState({ visible: false });

  render() {
    const { selectedPreference } = this.state;
    const { currentColumns } = this.props;

    let preferenceChanged = true;

    if (selectedPreference) {
      preferenceChanged = (selectedPreference.checked_tabs === currentColumns);
    }

    return (
      <React.Fragment>
        <Dropdown
          overlay={this.getMenu}
          placement="bottomCenter"
        >
          <Button>{selectedPreference ? selectedPreference.preference_name : 'Table Views'} {!preferenceChanged && '*'}</Button></Dropdown>
        <Button isDisabled={preferenceChanged} onClick={this.saveSelectedPreference}>
          <SaveOutlined />
        </Button>
        {this.getModal()}
      </React.Fragment>
    );
  }
}

export const ViewSelector = connect(
  (state: any) => {
    return {
      user: state.Auth.authUser,
      userPreferences: state.App.userPreference,
    };
  },
  { addUserTablePreference, editUserTablePreference, deleteUserTablePreference },
// @ts-ignore
)(ViewSelectorConnected);
