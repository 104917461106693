import styled from 'styled-components';

export const FileListContainer = styled.div`
  padding-top: 10px;
  width: 100%;
`;

export const SingleFile = styled.div`

`;
