import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from 'components/utility/intlMessages';
import { User } from 'core/entities/User';
import { getBackgroundColor } from 'helpers/utility';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { UserOutlined } from '@ant-design/icons';


interface Props {
  authUser: User,
}

interface State {
  visible: boolean,
  backgroundColor: string,
}

class TopbarUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      backgroundColor: getBackgroundColor(this.props.authUser.id || 0),
    };
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = () => {
    this.setState(({ visible }) => ({
      visible: !visible,
    }));
  }

  render() {
    const menu = (
      <TopbarDropdownWrapper>
        <Menu className="isoUserDropdown" selectedKeys={[]}>
          <Menu.Item>
            <Link to="/my-account" className="isoDropdownLink">
              <IntlMessages id="topbar.account" className="isoDropdownLink" />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <a href="https://help.timeivy.com/" className="isoDropdownLink" target="_blank">
              <IntlMessages id="topbar.help" className="isoDropdownLink" />
            </a>
          </Menu.Item>
          <Menu.Item>
            <Link to="/logout" className="isoDropdownLink">
              <IntlMessages id="topbar.logout" className="isoDropdownLink" />
            </Link>
          </Menu.Item>
        </Menu>
      </TopbarDropdownWrapper>
    );

    return (
      <Dropdown overlay={menu}>
        <UserOutlined style={{ fontSize: 17 }} />
        {/* <Avatar
          size="small"
          style={{ backgroundColor: this.state.backgroundColor }}
        >
          {this.props.authUser.getFirstInitial()}
        </Avatar> */}
      </Dropdown>
    );
  }
}

export default connect(
  (state: any) => ({ authUser: state.Auth.authUser }),
)(TopbarUser);
