import { Max } from 'class-validator';
import { ColumnConfig, FieldDefinition } from 'components';
import { eInputType } from 'components/DynamicForm';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class EntityFile extends BaseEntity {
  static NAME = 'file';
  static CONTENT_TYPE_NAME = 'entityfile';
  static END_POINT = 'file_management/files';

  @Max(255)
  name = '';

  file?: File = undefined;
  thumbnail: string = '';

  @Max(10)
  extension: string = '';

  @Max(255)
  original_name: string = '';
  object_id: number = 0;
  uploaded_by: number = 0;
  content_type: number = 0;

  readonly content_type_name!: string;
  readonly related_object_name!: string;
  readonly uploaded_by_name!: string;
  readonly file_preview!: string;

  public static FORM_FIELDS: FieldDefinition<EntityFile>[] = [
    { key: 'id', dataType: eDataType.STRING, inputType: eInputType.TEXT, isDisabled: true },
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    { key: 'extension', dataType: eDataType.STRING, inputType: eInputType.TEXT, isDisabled: true },
    { key: 'related_object_name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isDisabled: true },
    {
      key: 'content_type_name',
      label: ' ',
      dataType: eDataType.STRING,
      inputType: eInputType.BUTTON,
      fieldOptions: {
        appearance: 'link',
        children: 'Related Object',
      },
    },
    {
      key: 'object_id',
      inputType: eInputType.HIDDEN,
    },
    {
      key: 'uploaded_by',
      inputType: eInputType.HIDDEN,
    },
    {
      key: 'content_type',
      inputType: eInputType.HIDDEN,
    },
    {
      key: 'uploaded_by_name',
      label: 'Uploaded By',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT,
      isDisabled: true,
    },
    {
      key: 'file_preview',
      label: ' ',
      accessor: 'file',
      dataType: eDataType.STRING,
      inputType: eInputType.IMAGE_VIEW,
      isDisabled: true,
    },
    {
      key: 'file',
      label: ' ',
      dataType: eDataType.IMAGE,
      inputType: eInputType.FILE,
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'id' },
    { key: 'name', isClickable: true },
    { key: 'extension' },
    { key: 'uploaded_by_name', label: 'Uploaded By' },
  ];

  constructor(obj: Partial<EntityFile>) {
    super();

    copyExistingProps(this, obj);
  }
}
