import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getBottomLeft, getTopRight } from 'ol/extent';
import { Point } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import View from 'ol/View';
import React from 'react';
import { LatLong, Marker } from './entities';
import {
  Checkbox,
} from '@atlaskit/checkbox';

const icon = require('image/marker-icon.png');

interface Props {
  markers?: Marker[]
  onViewChange: (topRight: Coordinate, bottomLeft: Coordinate) => void
  resetView: () => void
  onMarkerClicked: (id: number) => void
  centerMapToLatLong?: (latLong: LatLong) => void
  className: string
}

interface State {
  filterByMap: boolean
}

export class MapView extends React.Component<Props, State> {
  mapDivId: string;
  map?: Map;
  vectorLayer: VectorLayer;
  mapLayer?: TileLayer;
  iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 46],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.PIXELS,
      src: icon,
    }),
  });

  static defaultProps = {
    onMarkerClicked: () => {
    },
    className: '',
  };

  constructor(props: any) {
    super(props);

    this.mapDivId = `map-field-view`;
    const vectorSource = new VectorSource({
      features: [],
    });

    this.vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    this.vectorLayer.on('change:maxResolution' || 'change:minResolution', (e) => {
      console.log(e);
    });

    this.state = {
      filterByMap: false,
    };
  }

  componentDidMount(): void {
    // this is canada lat long. in the future this will change
    this.setupMap(-79.347015, 43.651070, 6);
  }

  getIcon = ({ name, gps_location, id }: Marker) => {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([gps_location.longitude, gps_location.latitude])),
      id,
      name,
    });

    iconFeature.setStyle(this.iconStyle);
    return iconFeature;
  };

  setupMap = (longitude: number, latitude: number, zoom = 16) => {
    const rasterLayer = new TileLayer({
      source: new OSM(),
    });

    this.mapLayer = rasterLayer;
    const element = document.getElementById('popup');
    const target = document.getElementById(this.mapDivId);

    if (!element || !target) return;

    const map = new Map({
      view: new View({
        center: fromLonLat([longitude, latitude]),
        zoom,
      }),
      layers: [rasterLayer, this.vectorLayer],
      target: this.mapDivId,
    });

    map.on('click', evt => {
      const feature = map.forEachFeatureAtPixel(evt.pixel, feature => feature);

      if (feature) {
        this.props.onMarkerClicked(feature.get('id'));
      }
    });

    map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }

      const pixel = map.getEventPixel(e.originalEvent);
      const hit = map.hasFeatureAtPixel(pixel);

      map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    map.on('rendercomplete', _ => {
      map.updateSize();
    });

    map.on('moveend', () => {
      if (this.state.filterByMap) {
        this.onMapViewUpdate();
      }
    });

    this.map = map;
  };

  onMapViewUpdate = () => {
    if (!this.map)
      return;

    const extend = this.map.getView().calculateExtent();
    this.props.onViewChange(toLonLat(getTopRight(extend)), toLonLat(getBottomLeft(extend)));
  };

  toggleCheckBox: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ filterByMap: event.currentTarget.checked }, () => {
      this.state.filterByMap ? this.onMapViewUpdate() : this.props.resetView();
    });
  };

  render() {
    this.vectorLayer.getSource().clear(true);
    this.vectorLayer.getSource().addFeatures((this.props.markers || []).map(this.getIcon));

    return <React.Fragment>
      <Checkbox
        onChange={this.toggleCheckBox}
        label="Filter by map"
      />
      <div className={this.props.className}>
        <div id={this.mapDivId} style={{ height: '100%' }}/>
        <div id="popup"/>
      </div>
    </React.Fragment>;
  }
}
