import * as React from 'react';
import { TargetType } from 'helpers/entities';

export const actions = {
  SHOW_POPOUT_CONTENT: 'SHOW_POPOUT_CONTENT',
  HIDE_POPOUT_CONTENT: 'HIDE_POPOUT_CONTENT',
  SET_CONTENT: 'SET_CONTENT',
  SET_TITLE: 'SET_TITLE',
  SET_ON_CANCEL: 'SET_ON_CANCEL',
  showContent: () => ({ type: actions.SHOW_POPOUT_CONTENT }),
  hideContent: () => ({ type: actions.HIDE_POPOUT_CONTENT }),
  setContent: (target: TargetType, content: React.ComponentType, title?: string) => ({
    type: actions.SET_CONTENT,
    title,
    content,
    target,
  }),
  setTitle: (title: string) => ({ type: actions.SET_TITLE, title }),
  setOnCancel: (onCancel: Function) => ({ type: actions.SET_ON_CANCEL, onCancel }),
};
