import React from 'react';
import ImageViewer from 'react-images-viewer';
import { deleteFile, getImages } from 'core/api/files';
import { DeleteTwoTone } from '@ant-design/icons';
import { FileUploadForm, Spinner, showConfirm } from 'components';
import { connect } from 'react-redux';
import { ImageEntity } from './entities';
import { Image, ImagesContainer, ImageDiv, GalleryContainer } from './Gallery.style';


interface Props {
  isOpen: boolean
  showThumbnail: boolean
  className: string
  entityId?: number
  userId: number
  contentTypes: Record<string, { id: number }>
  onUpdateFiles: (files: ImageEntity[]) => void
}

interface State {
  currentImage: number
  isOpen: boolean
  images: ImageEntity[]
  isLoading: boolean
  contentType: number
}

class GalleryComponent extends React.Component<Props, State> {
  static defaultProps = {
    isOpen: false,
    showThumbnail: true,
  };

  state = {
    isLoading: true,
    currentImage: 0,
    isOpen: this.props.isOpen,
    images: [],
    isFormOpen: false,
    contentType: -1,
  };

  async componentDidMount() {
    const { entityId, className, contentTypes } = this.props;
    const contentTypeId = contentTypes[className].id;

    this.setState({ contentType: contentTypeId });

    if (entityId) {
      const images = await getImages(entityId, className) || [];

      this.parseImages(images);
    } else {
      this.setState({ isLoading: false });
    }
  }

  parseImages = (images: any[]) => {
    const parsedImages = images.map((image) => new ImageEntity({
      src: image.file,
      name: image.name,
      thumbnail: image.thumbnail,
      id: image.id,
    }));

    this.setState({ images: parsedImages, isLoading: false });
  };

  handleUploadCompleted = async (image: any) => {
    this.setState((prevState) => ({
      images: [
        new ImageEntity({
          src: image.file,
          name: image.name,
          thumbnail: image.thumbnail,
          id: image.id,
        }), ...prevState.images,
      ],
    }));
  };

  handleClickNext = () => this.setState((state: State) => ({ ...state, currentImage: state.currentImage + 1 }));
  handleClickPrev = () => this.setState((state: State) => ({ ...state, currentImage: state.currentImage - 1 }));
  handleOnClose = () => this.setState({ isOpen: false });
  handleThumbnailClick = (index: number) => this.setState({ currentImage: index });
  handleImageClick = (index: number) => this.setState({ isOpen: true, currentImage: index });

  getImage = ({ id, src }: ImageEntity, index: number) => {
    if (!id)
      return;

    return (
      <ImageDiv key={id}>
        <DeleteTwoTone
          className="icon"
          twoToneColor="#eb2f96"
          onClick={() => {
            const onOkPressed = () => deleteFile(id).then(_ => {
              this.setState((prevState) => ({ images: prevState.images.filter(img => img.id !== id) }));
            });

            showConfirm(onOkPressed, 'image');
          }} />
        <Image src={src} onClick={() => this.handleImageClick(index)}/>
      </ImageDiv>
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.images && (prevState.images.length !== this.state.images.length)) {
      this.props.onUpdateFiles(this.state.images);
    }
  }

  render() {
    const { currentImage, images, isLoading, contentType }: Partial<State> = this.state;
    const { showThumbnail, entityId, userId } = this.props;

    return (
      <GalleryContainer>
        <FileUploadForm
          onUploadComplete={this.handleUploadCompleted}
          userId={userId}
          entityId={entityId}
          contentType={contentType}
        />
        <ImagesContainer>
          {isLoading
            ? <Spinner/>
            : images.map((image, index) => this.getImage(image, index))
          }
        </ImagesContainer>
        <ImageViewer
          imgs={images}
          isOpen={this.state.isOpen}
          onClose={this.handleOnClose}
          currImg={currentImage}
          showThumbnails={showThumbnail}
          onClickThumbnail={this.handleThumbnailClick}
          onClickNext={this.handleClickNext}
          onClickPrev={this.handleClickPrev}
        />
      </GalleryContainer>
    );
  }
}

export const Gallery = connect(
  (state: any) => ({
    userId: state.Auth.authUser.id,
    contentTypes: state.App.contentTypes,
  }),
  {},
)(GalleryComponent);
