import React from 'react';
import { Skeleton } from 'antd';


interface Props {
  rows?: number
}

export class LoadingSkeleton extends React.PureComponent<Props> {
  range = (len: number) => {
    const arr: number[] = [];
    for (let i = 0; i < len; i++) {
      arr.push(i);
    }
    return arr;
  };

  render() {
    const { rows = 7 } = this.props;
    return (
      <div style={{ height: '100%', width: '100%' }}>
        {this.range(rows).map((value) => (
          <React.Fragment key={value}>
            <Skeleton key={`${value}`} active title={{ width: '20%' }} paragraph={false}/>
            <Skeleton key={`${value}_`} active title={{ width: '80%' }} paragraph={false}/>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
