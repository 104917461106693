import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../helpers/AsyncFunc';

export interface IRoute {
  path: string,
  name: string,
  component: any,
  exact?: boolean,
}

export const routes: IRoute[] = [
  {
    path: 'dashboard',
    component: asyncComponent(() => import('../pages/Dashboard/Dashboard')),
    name: 'Dashboard',
  },
  {
    path: 'garden',
    component: asyncComponent(() => import('../pages/Garden/Garden')),
    name: 'Garden',
  },
  {
    path: 'activities',
    component: asyncComponent(() => import('../pages/Activities/Activities')),
    name: 'Activities',
  },
  {
    path: 'projects',
    component: asyncComponent(() => import('../pages/Projects/Projects')),
    name: 'Activities',
  },
  {
    path: 'tags',
    component: asyncComponent(() => import('../pages/Tags/Tags')),
    name: 'Activities',
  },
  {
    path: 'blank-page',
    component: asyncComponent(() => import('../pages/BlankPage/BlankPage')),
    name: 'Blank Page',
  },
  {
    path: 'units',
    component: asyncComponent(() => import('../pages/Units/Units')),
    name: 'Units',
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../pages/Users/Users')),
    name: 'Users',
  },
  {
    path: 'calendar',
    component: asyncComponent(() => import('../pages/CalendarPage/CalendarPage')),
    name: 'Calendar',
  },
  {
    path: 'units/blank-page',
    component: asyncComponent(() => import('../pages/BlankPage/BlankPage')),
    name: 'Blank Page',
  },
  {
    path: 'my-account',
    component: asyncComponent(() => import('../pages/MyAccount/MyAccount')),
    name: 'My Account',
  },
  {
    path: 'files',
    component: asyncComponent(() => import('../pages/FilesPage/FilesPage')),
    name: 'Files',
  },
  {
    path: 'importer',
    component: asyncComponent(() => import('../pages/FileImporter/FileImporter')),
    name: 'Importer',
  },
  {
    path: 'facilities',
    component: asyncComponent(() => import('../pages/FacilitiesPage/FacilitiesPage')),
    name: 'Facilities',
  },
  {
    path: 'facility-categories',
    component: asyncComponent(() => import('../pages/FacilityCategoriesPage/FacilityCategoriesPage')),
    name: 'Facility Categories',
  },
  {
    path: 'users/invites',
    component: asyncComponent(() => import('../pages/WorkspaceInvitations/WorkspaceInvitations')),
    name: 'Invites',
  },
  {
    path: 'users/teams',
    component: asyncComponent(() => import('../pages/TeamsPage/TeamsPage')),
    name: 'Teams',
  },
  {
    path: 'users/roles',
    component: asyncComponent(() => import('../pages/RolesPage/RolesPage')),
    name: 'Roles',
  },
  {
    path: 'user-events',
    component: asyncComponent(() => import('../pages/UserEvents/UserEvents')),
    name: 'User Events',
  },
  {
    path: 'work-requests',
    component: asyncComponent(() => import('../pages/WorkRequestsPage/WorkRequestsPage')),
    name: 'Work Requests',
  },
  {
    path: 'notifications',
    component: asyncComponent(() => import('../pages/NotificationsPage/NotificationsPage')),
    name: 'Notifications',
  },
  {
    path: 'work-orders',
    component: asyncComponent(() => import('../pages/WorkOrdersPage/WorkOrdersPage')),
    name: 'Work Orders',
  },
  {
    path: 'work-order-categories',
    component: asyncComponent(() => import('../pages/WorkOrderCategoriesPage/WorkOrderCategoriesPage')),
    name: 'Work Order Categories',
  },
];

interface Props {
  url: string,
  style: { [indes: string]: string },
}

function AppRouter(props: Props) {
  const { style } = props;

  return (
    <div style={style}>
      <Switch>
        <Route exact path="/">
          <Redirect to="dashboard" />
        </Route>

        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={path}
              path={`/${path}`}
              {...otherProps}
            />
          );
        })}
        <Route key="404" component={asyncComponent(() => import('../pages/404/404'))} />
      </Switch>
    </div>
  );
}

export default AppRouter;
