import React from 'react';
import memoize from 'memoize-one';
import queryString from 'query-string';
import { DynamicForm } from 'components';
import { connect } from 'react-redux';
import { actions } from 'redux/popOutContent/actions';
import { getClassConstructor } from '../../helpers/utility';
import { SingleContainer } from '../InteractiveTable/InteractiveTable.style';

interface IQueryParams {
  modal?: string[],
  sidebar?: string[],
}

interface Props {
  location: any,
  history: any,
}

class HistoryFormState {
  [contentTypeName: string]: object;
}

/**
 * Allows to open a form in a "modal" / "sidebar", via URL params. 
 * ie. "?sidebar=[model],[id]" -> update an entity
 * ie. "?modal=[model],null"   -> create a new entity
 * 
 * If called with the history API, state can also be pased.
 *   history.push({
 *     search: `?modal=x,2`,
 *     state: { foo: 'bar' }
 *   });
*/
class HistoryForm extends React.Component<Props & typeof actions> {

  content = memoize(
    (search: any) => {
      const { sidebar, modal }: IQueryParams = queryString.parse(search, { arrayFormat: 'comma' });

      if (sidebar && modal) {
        console.warn(
          'Cannot pass both "modal" and "sidebar" query params at the same time'
        );
        return;
      }

      const target = sidebar || modal;
      const targetName = sidebar ? 'sidebar' : 'modal';

      if (!target) {
        return;
      } else if (target.length !== 2 || target.some(x => !x)) {
        console.error(`Must pass query params with format: "?${targetName}=[model],[id]"`);
        return;
      }

      const [className, id] = target;
      const entityId = parseInt(id, 10);
      const classConstructor = getClassConstructor(className);
      const { END_POINT: endPoint, FORM_FIELDS: formField } = classConstructor;

      const handleOnLoad = (data: Record<string, any>) => {
        const historyState = this.props.history.location.state;
        const title = historyState && historyState.title || data.name;
        this.props.setTitle(title);
      };

      const handleSuccess = (savedData: typeof classConstructor, shouldCloseOnSuccess: boolean) => {
        // Use the same query param values as unique identifier so
        // the requester can listen to the history, on save success.
        const requesterId = target.join();

        const state: HistoryFormState = {
          [requesterId]: savedData
        };

        this.props.history.push({ search: '' }, state);

        if (shouldCloseOnSuccess) {
          this.props.hideContent();
        }
      };

      const content = () => <SingleContainer>
        <DynamicForm
          endpoint={endPoint}
          classConstructor={classConstructor}
          fields={formField}
          entityId={entityId}
          onLoad={handleOnLoad}
          onSuccess={handleSuccess}
          showContinueEditing={false}
          showDeleteButton={false}
        /></SingleContainer>;

      this.props.setOnCancel(() => {
        this.props.history.push({ search: '' });
      });

      this.props.setContent(targetName, content);
      this.props.showContent();
    },
  );

  render() {
    this.content(this.props.location.search);
    return null;
  }
}

export default connect(
  (state: any) => ({ context: state.popOutContent }),
  { ...actions },
)(HistoryForm);
