import styled from 'styled-components';
import { palette } from 'styled-theme';

const BoxTitle = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: ${palette('text', 0)};
  margin: 0;
  margin-bottom: 12px;
`;

const BoxSubTitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${palette('text', 3)};
  line-height: 24px;
`;

export { BoxTitle, BoxSubTitle };
