import Form, { ErrorMessage, Field } from '@atlaskit/form';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { ContextForm, eInputType, showNotification } from 'components';
import { deleteEntity, getEntity, saveEntity } from 'core/api';
import { capitalize, getClassConstructor, toString, validatebyClassAsync } from 'helpers/utility';
import React from 'react';
import ReactDOM from 'react-dom';
import { FieldDefinition, getComponentType, LoadingSkeleton } from '.';
import { TypeForm } from './Components/TypeForm/TypeForm';
import { FooterStyle, FormFooter, FormWrapper } from './DynamicForm.style';
import { debounce } from 'lodash';

interface Data {
  id?: number | undefined,
  [index: string]: any
}

export interface DynamicFormProps {
  classConstructor: Constructor<any>,
  onSuccess: Function,
  onCancel: Function,
  onLoad: (initialData: Data) => void,
  fields: FieldDefinition<any>[],
  entityId?: number,
  endpoint: string,
  onSubmit?: (data: any) => void,
  showCancelButton?: boolean
  /** 
   * Initial data on the form. Only used once on initialization, so further
   * updates to this prop are not tracked.
   */
  initialData?: Data
  /**
   * Data added to the form data before submission.
   */
  extraData?: { [index: string]: any }
  submitButtonText?: string | JSX.Element
  footerStyle?: FooterStyle
  stickyFooter?: boolean
  showDeleteButton: boolean
  customRequest?: (data: any) => Promise<Record<string, any>>
  showContinueEditing: boolean
  typeFormEnabled: boolean
  /**
   * Whether to show Saved and Delete success notifications
   */
  showSuccessNotifications: boolean
}

interface State {
  clickedSubmit: boolean,
  isSubmitting: boolean,
  errors: Record<string, any>,
  initialData?: Data,
  isLoading: boolean,
  isNewEntry: boolean,
  showPopup: boolean,
  entityId?: number
}

export class DynamicForm extends React.Component<DynamicFormProps, State> {
  static defaultProps = {
    onSuccess: () => { },
    onCancel: () => { },
    onLoad: () => { },
    showCancelButton: false,
    showDeleteButton: true,
    stickyFooter: true,
    showContinueEditing: false,
    submitButtonText: '',
    typeFormEnabled: false,
    showSuccessNotifications: true,
  };

  constructor(props: DynamicFormProps) {
    super(props);

    this.state = {
      clickedSubmit: false,
      isSubmitting: false,
      errors: {},
      isLoading: false,
      isNewEntry: false,
      showPopup: false,
      entityId: undefined,
      initialData: props.initialData,
    };
  }
  public async componentDidMount() {
    const isNewEntry = !this.props.entityId;

    this.setState({ isLoading: true, isNewEntry });

    const { endpoint, entityId } = this.props;

    if (entityId) {
      const initialData = await getEntity<Data>(endpoint, entityId);
      this.setState({ initialData, isLoading: false, entityId }, () => {
        const { onLoad } = this.props;

        onLoad(initialData);
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  private debouncedValidateForm = debounce((data: Data, key?: keyof Data) => this.validateForm(data, key), 250);

  /**
   * Validates the form setting any found errors on the state.
   *
   * @param data
   * @param key, If passed, only the field with this key will be validated.
   */
  private validateForm = async (data: Data, key?: keyof Data) => {
    const { classConstructor } = this.props;
    const errors = await validatebyClassAsync<Data>(data, classConstructor);

    if (key) {
      const newError = errors[key] ? { [key]: errors[key] } : { [key]: undefined };

      this.setState(state => ({
        errors: {
          ...state.errors,
          ...newError,
        },
      }));

      return !!newError[key];
    } else {
      this.setState({ errors });
    }

    return !!Object.keys(errors).length;
  };

  private onSubmit = async (data: Data, shouldCloseOnSuccess: boolean = true) => {
    this.setState({ clickedSubmit: true, isSubmitting: true });

    const errors = await this.validateForm(data);
    if (errors === undefined || errors) {
      return;
    }

    const {
      endpoint,
      extraData = {},
      onSubmit,
      customRequest,
      onSuccess,
      classConstructor
    } = this.props;

    if (onSubmit) {
      onSubmit({ ...data, ...extraData });
    }
    else {
      const entity = Object.assign(
        Object.create(classConstructor['prototype']),
        data,
        { id: this.state.entityId },
      );
      // TODO analyze if in the future we want to have a file one depending if the project needs it
      let responseData;

      if (customRequest) {
        responseData = await customRequest({ ...entity, ...extraData });
      } else {
        responseData = await saveEntity<typeof classConstructor>(endpoint, { ...entity, ...extraData });
      }

      if (responseData) {
        this.notify('success', 'Saved');

        !shouldCloseOnSuccess && this.setState({
          initialData: responseData,
          isNewEntry: false,
          entityId: responseData.id
        });

        onSuccess(responseData, shouldCloseOnSuccess);
      } else {
        this.notify('error', 'something failed');
      }
    }

    this.setState({ isSubmitting: false });
  };

  // TODO. add some sort of error reporting package
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(errorInfo);
    console.log(error);
  }

  private notify(type: 'success' | 'info' | 'error' | 'warning', message: string) {
    if (type === 'success' && !this.props.showSuccessNotifications) return;

    showNotification(type, { message });
  }

  private formItem = (field: FieldDefinition<any>, getValues: Function) => {
    const { key, label, inputType, fieldOptions = {}, isRequired, isDisabled, accessor = '' } = field;
    const { errors, clickedSubmit, initialData = {} }: Partial<State> = this.state;

    let capitalizedLabel = '';
    if (label !== false) {
      const labelString = label && typeof label === 'string' ? label : toString(key);
      capitalizedLabel = capitalize(labelString);
    }

    const error: any = errors[key] || '';
    const [Component, className]: [Function, string] = getComponentType(inputType);

    const defaultValue = initialData[accessor || key];

    switch (inputType) {
      case eInputType.BUTTON:
        return (
          <Component className={className} key={key} {...fieldOptions} onClick={() => {
            const entityId = initialData.object_id;
            const classConstructor = getClassConstructor(initialData.content_type_name);

            ReactDOM.render(
              <ContextForm
                key={key}
                entityId={entityId}
                classConstructor={classConstructor}
              />,
              document.getElementById('drawer'));
          }} />
        );

      case eInputType.IMAGE_VIEW:
        return (
          <Component src={defaultValue} key={key} />
        );

      case eInputType.MAP_VIEW:
        const address = getValues()[accessor];
        return (
          <Field name={key} key={key} defaultValue={initialData['gps_location']}>
            {({ fieldProps }: any) => <Component address={address} {...fieldProps} />}
          </Field>
        );

      case eInputType.HIDDEN:
        return <Field key={key} name={key} defaultValue={defaultValue}>
          {() => <input type="hidden" />}
        </Field>;
    }
    return (
      <Field
        name={key}
        label={capitalizedLabel}
        key={key}
        defaultValue={defaultValue}
        isRequired={isRequired}
        isDisabled={isDisabled}
      >
        {({ fieldProps, meta }: any) => {
          const handleBlur = () => {
            this.validateForm(getValues(), key);
            fieldProps.onBlur();
          };

          let handleChange: (e: any) => any = () => {
          };

          if (inputType !== eInputType.ASYNC_VALIDATION_TEXT_FIELD) {
            handleChange = (e: any) => {
              fieldProps.onChange(e);
              this.debouncedValidateForm(getValues(), key);
            };
          } else {
            Object.assign(fieldOptions, { ...fieldProps, validate: this.validateForm.bind(null, getValues(), key) });
          }

          const shouldRenderError = ((clickedSubmit || meta.touched) && error) || (this.props.typeFormEnabled && error);
          return (
            <React.Fragment>
              <Component
                className={className}
                autoComplete="off"
                {...fieldProps}
                isInvalid={shouldRenderError}
                onBlur={handleBlur}
                onChange={handleChange}
                {...fieldOptions}
              />
              {shouldRenderError && error.map((message: string, i: number) => <ErrorMessage key={i}>{message}</ErrorMessage>)}
            </React.Fragment>);
        }}
      </Field>
    );
  };

  handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      this.setState({ showPopup: visible });
      return;
    }

    if (this.state.isNewEntry) {
      this.props.onCancel();
    } else {
      this.setState({ showPopup: visible });
    }
  };

  handleConfirmDelete = async () => {
    const { endpoint } = this.props;
    const { entityId = 0 } = this.state;

    try {
      await deleteEntity(endpoint, entityId);
      this.props.onCancel(true);

      this.notify('success', 'Deleted successfully');
    } catch (e) {
      this.notify('error', 'Error deleting item');
    }
  };

  getMenu = (getValues: Function) => {
    const onClick = () => {
      this.onSubmit(getValues(), false);
    };

    return <Menu onClick={onClick}>
      <Menu.Item key={1}>
        Save and continue editing
      </Menu.Item>
    </Menu>;
  };

  isConfirmButtonDisabled = (getValues: Function) => {
    const { fields } = this.props;
    const formValues = getValues();
    const errors: Record<string, string[]> = this.state.errors;
    const keys = Object.keys(errors);

    return (
      keys.some(key => errors[key] !== undefined) ||
      fields.some(x => x.isRequired && formValues[x.key] == null)
    );
  };

  renderConfirmButton = (getValues: Function, isNewEntry: boolean) => {
    const { submitButtonText = '', showContinueEditing } = this.props;
    if (showContinueEditing) {
      return (
        <Dropdown.Button
          type='primary'
          htmlType='submit'
          disabled={this.isConfirmButtonDisabled(getValues)}
          icon={<CaretDownOutlined />}
          overlay={this.getMenu(getValues)}
        >
          {submitButtonText || (isNewEntry ? 'Create' : 'Save')}
        </Dropdown.Button>
      );
    }

    return (<Button
      type='primary'
      htmlType='submit'
      loading={this.state.isSubmitting}
      disabled={this.isConfirmButtonDisabled(getValues)}>
      {submitButtonText || (isNewEntry ? 'Create' : 'Save')}
    </Button>);
  };

  public render() {
    const { fields, showCancelButton, showDeleteButton, footerStyle, stickyFooter, typeFormEnabled } = this.props;
    const { isLoading, isNewEntry, showPopup, entityId } = this.state;

    if (isLoading) {
      return <LoadingSkeleton rows={fields.length} />;
    }

    const deleteButton = !isNewEntry && showDeleteButton;
    return (
      <FormWrapper>
        <Form onSubmit={this.onSubmit}>
          {({ formProps, getValues }: any) => (
            <form {...formProps} noValidate>
              {typeFormEnabled ? <TypeForm
                errors={this.state.errors}
                handleSubmit={this.onSubmit}
                getValues={getValues}
                validateForm={this.validateForm}
                entityId={entityId}
              >
                {fields.map(item => this.formItem(item, getValues))}
              </TypeForm> :
                <React.Fragment>
                  {fields.map(item => this.formItem(item, getValues))}
                  <FormFooter {...footerStyle} stickyFooter={stickyFooter}>
                    {deleteButton && <Popconfirm
                      visible={showPopup}
                      onVisibleChange={this.handleVisibleChange}
                      //@ts-ignore
                      title={`Delete this ${(this.props.classConstructor.NAME || 'item').toLowerCase()}?`}
                      okText="Yes"
                      cancelText="No"
                      okType="danger"
                      onConfirm={this.handleConfirmDelete}
                    >
                      <Button danger className="delete-button">
                        Delete
                      </Button>
                    </Popconfirm>}
                    {showCancelButton && <Button className="cancel-button"> Cancel </Button>}
                    {this.renderConfirmButton(getValues, isNewEntry)}
                  </FormFooter>
                </React.Fragment>}
            </form>
          )}
        </Form>
      </FormWrapper>
    );
  }
}
