import React from 'react';
import { actions } from 'redux/popOutContent/actions';
import { store } from 'redux/store';
import { DynamicForm } from 'components';
import { SingleContainer } from '../InteractiveTable/InteractiveTable.style';

interface Props {
  entityId: number
  classConstructor: Constructor<any>
}

export class ContextForm extends React.Component<Props> {
  componentDidMount(): void {
    this.renderDrawer();
  }

  componentDidUpdate(): void {
    this.renderDrawer();
  }

  renderDrawer = () => {
    const { classConstructor, entityId } = this.props;
    const handleLoad = (entity: Record<string, any>) => {
      store.dispatch(actions.setTitle(entity.name));
    };

    const { END_POINT: endPoint, FORM_FIELDS: formFields } = classConstructor as any;

    const Content = () => (
      <SingleContainer>
        <DynamicForm
          onLoad={handleLoad}
          endpoint={endPoint}
          classConstructor={classConstructor}
          fields={formFields}
          entityId={entityId}
        />
      </SingleContainer>
    );

    store.dispatch(actions.setContent('sidebar', Content, ' '));
    store.dispatch(actions.showContent());
  };

  render() {
    return null;
  }
}
