import { USER_PREFERENCE } from './constants';
import { UserPreferences } from 'core/entities';


export function getUserPreference(userId: number) {
  try {
    const preference = localStorage.getItem(`${USER_PREFERENCE}_${userId}`);
    return preference ? JSON.parse(preference) : {};
  } catch {
    return null;
  }
}

export function setUserPreference(userPreference: UserPreferences) {
  try {
    localStorage.setItem(
      `${USER_PREFERENCE}_${userPreference.userId}`,
      JSON.stringify(userPreference)
    );
  } catch (e) {
    console.log(e);
  }
}
