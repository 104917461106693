import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

export interface ConfettiProps {
  shouldThrow: boolean,
  confettiConfig?: any;  // library options.
  children?: any,
}

export const Confetti = (props: ConfettiProps) => {
  const { shouldThrow, children, confettiConfig } = props;

  useEffect(() => {
    if (document) {
      const defaultConfig: any = {};
      const element = document.getElementById('confetti-origin');

      if (element) {
        const rect = element.getBoundingClientRect();
        defaultConfig.origin = {
          x: (rect.x + (rect.width / 2)) / window.screen.width,
          y: (rect.y + rect.height) / window.screen.height,
        };

        if (shouldThrow) {
          confetti({ ...defaultConfig, ...confettiConfig });
        }
      }
    }
  }, [shouldThrow]);

  // We only use this wrapper div to be able to calculate an origin
  // for the confetti to be thrown from. Not needed if we use the
  // library default origin from the library, which is screen absolute.
  return <div id="confetti-origin">{children}</div>;
};
