import Tabs, { TabItem } from '@atlaskit/tabs';
import {
  ColumnConfig,
  DynamicForm,
  DynamicFormProps,
  DynamicTable,
  DynamicTableProps,
  FieldDefinition,
  FileList
} from 'components';
import { TargetType } from 'helpers/entities';
import { capitalize } from 'helpers/utility';
import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { actions } from 'redux/popOutContent/actions';
import { Gallery } from '../Gallery';

interface State {
  endpoint: string
  columnConfig: ColumnConfig[]
  fields: FieldDefinition<any>[]
  tableName: string
  displayName: string
}

interface Props {
  showFormDeleteButton?: boolean
  target?: TargetType
  endpoint?: 'string'
  classConstructor: ModelConstructor<any>
  customSubmitRequest?: (data: any) => Promise<Record<string, any>>
  showImageTab: boolean
  showFilesTab: boolean
  dynamicFormProps?: Partial<DynamicFormProps>
  dynamicTableProps?: Partial<DynamicTableProps>
}

type ConnectedProps = typeof actions;

export class InteractiveTable extends React.Component<Props & ConnectedProps, State> {
  static defaultProps = {
    showFilesTab: false,
    showImageTab: false,
  };
  ref: RefObject<DynamicTable> = React.createRef();

  constructor(props: Props & ConnectedProps) {
    super(props);
    // @ts-ignore
    const { END_POINT: endpoint, columnConfig, FORM_FIELDS: fields, CONTENT_TYPE_NAME: tableName, NAME: displayName } = this.props.classConstructor;

    if (!endpoint || !columnConfig || !fields) {
      throw new Error(`${this.props.classConstructor} doesn't have 'endpoint, columnConfig, or fields' property`);
    }

    this.state = {
      endpoint,
      columnConfig,
      fields,
      tableName,
      displayName,
    };
  }

  private handleClick = (entityId?: number) => {
    let imageTabRef: any;
    let fileTabRef: any;

    const handleSubmit = async (_: any, shouldCLoseOnSuccess: boolean) => {
      if (shouldCLoseOnSuccess) {
        this.props.hideContent();
      }

      this.ref.current && this.ref.current.refreshTable();
    };

    const updateTitle = (type: 'image' | 'file', data: any[]) => {
      if (!data) return;

      const ref = type === 'image' ? imageTabRef : fileTabRef;

      if (ref) {
        const displayName = type === 'image' ? 'Images' : 'Files';

        ref.props.data.label = `${displayName} ${data.length ? `(${data.length})` : ''}`;
        ref.updater.enqueueForceUpdate(ref);
      }
    };

    const updateImagesTabTitle = updateTitle.bind(null, 'image');
    const updateFilesTabTitle = updateTitle.bind(null, 'file');

    const handleLoad = (data: Record<string, any>) => {
      const title = data.name || 'Edit Item';
      this.props.setTitle(title);

      updateImagesTabTitle(data.images);
      updateFilesTabTitle(data.files);
    };

    if (!entityId) {
      const title = capitalize(this.state.displayName);

      this.props.setTitle(`New ${title}`);
    }

    const handleCancel = async (shouldRefresh = false) => {
      this.props.hideContent();

      if (shouldRefresh) {
        this.ref.current && this.ref.current.refreshTable();
      }
    };

    const MainTab = () => <DynamicForm
      classConstructor={this.props.classConstructor}
      endpoint={this.state.endpoint}
      fields={this.state.fields}
      onSuccess={handleSubmit}
      onCancel={handleCancel}
      onLoad={handleLoad}
      customRequest={this.props.customSubmitRequest}
      entityId={entityId}
      showDeleteButton={this.props.showFormDeleteButton}
      showContinueEditing={false}
      {...this.props.dynamicFormProps}
    />;

    const ImageTab = () => (
      <Gallery
        entityId={entityId}
        className={this.state.tableName}
        onUpdateFiles={updateImagesTabTitle}
      />);

    const FileTab = () => (
      <FileList
        entityId={entityId}
        className={this.state.tableName}
        onUpdateFiles={updateFilesTabTitle}
      />);

    const TabWrapper = (props: any) => {
      if (props.data.label.includes('Images')) {
        return <TabItem
          {...props}
          className={props.data.label}
          key={props.data.label}
          label={props.data.label}
          ref={tabItem => imageTabRef = tabItem}
        />;
      } else if (props.data.label.includes('Files')) {
        return <TabItem
          {...props}
          className={props.data.label}
          key={props.data.label}
          label={props.data.label}
          ref={tabItem => fileTabRef = tabItem}
        />;
      }

      return <TabItem {...props} key={props.data.label} />;
    };

    const tabs = [{ label: 'Main', content: <MainTab />, key: 'main' }];

    this.props.showImageTab && tabs.push({ label: `Images`, content: <ImageTab />, key: 'image' });
    this.props.showFilesTab && tabs.push({ label: `Files`, content: <FileTab />, key: 'file' });

    const Content = () => <Tabs
      components={{ Item: TabWrapper }}
      tabs={tabs}
    />;


    this.props.setContent(this.props.target || 'sidebar', Content);
    this.props.showContent();
  };

  render() {
    return (
      <DynamicTable
        ref={this.ref}
        tableName={this.state.tableName}
        endpoint={this.state.endpoint}
        columnConfig={this.state.columnConfig}
        onRowClick={this.handleClick}
        entityClass={this.props.classConstructor}
        showSearchBar
        {...this.props.dynamicTableProps}
      />
    );
  }
}

export default connect(
  (state: any) => ({ context: state.popOutContent }),
  { ...actions },
)(InteractiveTable);
