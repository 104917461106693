import React from 'react';
import { deleteFile, getFiles } from 'core/api/files';
import { DeleteTwoTone } from '@ant-design/icons';
import { FileUploadForm, Spinner, showConfirm } from 'components';
import { connect } from 'react-redux';
import { EntityFile } from '../../core/entities';
import { ListWrapper } from '../ScrollbarList/ScrollbarList.style';
import { FileListContainer, SingleFile } from './FileList.style';

interface Props {
  isOpen: boolean
  showThumbnail: boolean
  className: string
  entityId?: number
  userId: number
  contentTypes: Record<string, { id: number }>
  onUpdateFiles: (files: EntityFile[]) => void
}

interface State {
  isOpen: boolean
  files: EntityFile[]
  isLoading: boolean
  contentType: number
}

class FileListComponent extends React.Component<Props, State> {
  static defaultProps = {
    isOpen: false,
    showThumbnail: true,
  };

  state = {
    isLoading: true,
    isOpen: this.props.isOpen,
    files: [],
    isFormOpen: false,
    contentType: -1,
  };

  async componentDidMount() {
    const { entityId, className, contentTypes } = this.props;
    let files = [];

    if (entityId) {
      files = await getFiles(entityId, className) || [];
    }

    const contentTypeId = contentTypes[className].id;
    this.setState({ contentType: contentTypeId, files: files, isLoading: false });
  }

  handleUploadCompleted = async (file: Record<string, string>) => {
    this.setState((prevState) => ({
      files: [file as any, ...prevState.files],
    }));
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.files && (prevState.files.length !== this.state.files.length)) {
      this.props.onUpdateFiles(this.state.files);
    }
  }

  getFile = ({ file, id, name }: EntityFile) => {
    if (typeof file !== 'string' || !id)
      return null;

    return (
      <SingleFile key={id} className="isoSingle">
        <i className="isoAvatar ion-document" />
        <a href={file} className="isoName">{name}</a>
        <DeleteTwoTone
          className="isoDeleteBtn"
          twoToneColor="#eb2f96"
          onClick={() => {
            const onOkPressed = () => deleteFile(id).then(_ => {
              this.setState((prevState) => ({ files: prevState.files.filter(file => file.id !== id) }));
            });

            showConfirm(onOkPressed, 'file');
          }} />
      </SingleFile>
    );
  };

  render() {
    const { files, isLoading, contentType }: Partial<State> = this.state;
    const { entityId, userId } = this.props;

    return (<FileListContainer>
      <FileUploadForm
        onUploadComplete={this.handleUploadCompleted}
        userId={userId}
        entityId={entityId}
        contentType={contentType}
      />
      {isLoading
        ? <Spinner />
        : <ListWrapper className="isoListWrapper">
          <div className="isoList">
            {files.map(this.getFile)}
          </div>
        </ListWrapper>
      }
    </FileListContainer>);
  }
}

export const FileList = connect(
  (state: any) => ({
    userId: state.Auth.authUser.id,
    contentTypes: state.App.contentTypes,
  }),
  {},
)(FileListComponent);
