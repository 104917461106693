import { ContentType } from 'core/entities/ContentType';
import { TablePreference, UserPreferences } from 'core/entities';
import { setUserPreference } from 'helpers/store';

export function formatContentType(contentTypes: ContentType[]) {
  const returnObject: Record<string, ContentType> = {};

  contentTypes.forEach(contentType => {
    const key = contentType.model;
    returnObject[key] = contentType;
  });

  return returnObject;
}

export function addTablePreference(tablePreference: TablePreference, userPreference: UserPreferences) {
  const newPreference = new UserPreferences(userPreference);
  try {
    newPreference.addUserPreference(tablePreference);
    setUserPreference(newPreference);
  } catch (e) {
    console.log(e);
  }
  return newPreference;
}

export function editTablePreference(tablePreferences: TablePreference, userPreference: UserPreferences) {
  const newPreferences = new UserPreferences(userPreference);

  newPreferences.editTablePreference(tablePreferences);
  setUserPreference(newPreferences);

  return newPreferences;
}

export function removeTablePreference(tablePreference: TablePreference, userPreference: UserPreferences) {
  const newPreferences = new UserPreferences(userPreference);

  newPreferences.deleteUserPreference(tablePreference);
  setUserPreference(newPreferences);

  return newPreferences;
}
