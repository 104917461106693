import React from 'react';
import { Col as AntCol, ColProps } from 'antd';
import ColStyle from './col.style';


export const Col = ColStyle((props: ColProps) => (
  <AntCol {...props}>
    {props.children}
  </AntCol>
));
