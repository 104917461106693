import { validate, validateSync, ValidationError } from 'class-validator';
import { DurationGoal, Asset, ContentType, Facility, User, UserEvent, WorkOrder } from 'core/entities';
import { store } from 'redux/store';
import { capitalize as _capitalize, lowerCase } from 'lodash';
import { PRIORITY_COLORS, PriorityType } from './entities';
const humanizeDurationLib = require('humanize-duration');

/**
 * Custom humanizer with only hours and minutes and short english.
 */
const shortEnglishHumanizer = humanizeDurationLib.humanizer({
  language: "shortEn",
  spacer: '',
  delimiter: ' ',
  units: ['h', 'm'],
  round: true,
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

export function humanizeDuration(seconds?: number): string {
  if (seconds == null) {
    return '';
  } else if (seconds < 60) {
    return seconds > 0 ? '< 1m' : '0m';
  }

  return shortEnglishHumanizer(seconds * 1000);
}

export function getBackgroundColor(id: number): string {
  const colors = [
    '#003366',
    '#191970',
    '#3b5998',
    '#008080',
  ];

  return colors[id % colors.length];
}

export function desnakecase(str: string, separator: string = ' '): string {
  return str.split('_').filter(v => v).join(separator).toLowerCase();
}

export function decamelize(str: string, separator: string = ' '): string {
  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase();
}

/**
 * converts a string to a new string all in lower case separated by a space
 * @param str
 *
 * @example
 *
 * toString('--Foo-Bar--');
 * => 'foo bar'
 *
 * toString('fooBar');
 * // => 'foo bar'
 *
 * toString('__FOO_BAR__');
 * => 'foo bar'
 */
export function toString(str: string): string {
  return lowerCase(str);
}

/**
 * Converts an object literal to be an instance of the passed class.
 */
export function toInstance<T>(objLiteral: object | T, classConstructor: Constructor<T>, useConstructor = false): T {
  if (!objLiteral || objLiteral instanceof classConstructor) {
    return objLiteral;
  }

  const emptyInstance = useConstructor
    ? new classConstructor()
    : Object.create(classConstructor.prototype);

  return Object.assign(
    emptyInstance,
    objLiteral
  );
}

export function capitalize(str: string): string {
  return _capitalize(str);
}

export function capitalizeSnakeCase(str: string): string {
  const result = desnakecase(str);
  return capitalize(result);
}

export function copyExistingProps<T extends object>(target: T, source: T) {
  for (let i = 0, keys = Object.keys(source); i < keys.length; i += 1) {
    const key = keys[i];
    if (target.hasOwnProperty(key)) {
      // eslint-disable-next-line no-param-reassign
      target[key as keyof T] = source[key as keyof T];
    } else {
      TypeError(`Target object doesn't have property "${key}".`);
    }
  }
}

interface Data {
  id?: number | undefined,
  [index: string]: any
}

export const validatebyClassAsync = async <T>(
  data: Data,
  classConstructor: Constructor<any>
): Promise<Record<keyof T & string, string[]>> => {

  const entity = toInstance(data, classConstructor);
  const errors = await validate(entity);

  const errorMessages = {} as Record<string, string[]>;
  errors.forEach(({ property, constraints }: ValidationError) => {
    errorMessages[property] = Object.keys(constraints).map(
      key => constraints[key].replace(property, '')
    );
  });

  return errorMessages;
};

export const validateByClass = <T>(
  data: T,
  classConstructor: Constructor<any>
): Record<keyof T & string, string[]> => {

  const entity = toInstance<T>(data, classConstructor);
  const errors = validateSync(entity);

  const errorMessages = {} as Record<string, string[]>;
  errors.forEach(({ property, constraints }: ValidationError) => {
    errorMessages[property] = Object.keys(constraints).map(
      key => constraints[key].replace(property, '')
    );
  });

  return errorMessages;
};

export function getClassConstructor(contentTypeName: string) {
  const contentType: ContentType = store.getState().App.contentTypes[contentTypeName];

  switch (contentType.model) {
    case Asset.CONTENT_TYPE_NAME:
      return Asset;
    case User.CONTENT_TYPE_NAME:
      return User;
    case Facility.CONTENT_TYPE_NAME:
      return Facility;
    case WorkOrder.CONTENT_TYPE_NAME:
      return WorkOrder;
    case UserEvent.CONTENT_TYPE_NAME:
      return UserEvent;
    case DurationGoal.CONTENT_TYPE_NAME:
      return DurationGoal;
    default:
      throw new Error(`Can't find class for ${contentType.model}`);
  }
}

export function getPriorityColor(priority: PriorityType) {
  let color = '';
  switch (priority) {
    case 'high':
      color = PRIORITY_COLORS.HIGH;
      break;
    case 'medium':
      color = PRIORITY_COLORS.MEDIUM;
      break;
    case 'low':
      color = PRIORITY_COLORS.LOW;
      break;
  }

  return color;
}
