import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd/lib/index';
import appActions from 'redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import themes from 'settings/themes';
import { themeConfig } from 'settings';
import { Breadcrumbs } from 'components/Breadcrumbs';
import TopbarNotification from './topbarNotification';
import Icon from 'components/uielements/icon';

const { Header } = Layout;
const { toggleCollapsed, changeCurrent } = appActions;
const customizedTheme = themes[themeConfig.theme];


class Topbar extends Component {
  render() {
    const { toggleCollapsed, changeCurrent } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 60,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            >
              <Icon name="menu-outline" />
            </button>
            <Breadcrumbs changeCurrent={changeCurrent} />
          </div>
          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: 'notification' })}
              className="isoNotify"
            >
              <TopbarNotification />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
  }),
  { toggleCollapsed, changeCurrent },
)(Topbar);
