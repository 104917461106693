import styled from "styled-components";
import { Row, RowProps } from 'antd';


const RowStyle = Component => styled<typeof Row>(Component) <RowProps>`
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-flow: row wrap;
    margin-left: auto !important;
    margin-right: auto !important;
`;

export default RowStyle;
