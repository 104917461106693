import React from 'react';
import { Radio, Tooltip } from 'antd';
import { GroupRadioOption } from '../../entities';
import { RadioButton } from './RadioButtonGroup.style';
import { RadioProps } from 'antd/lib/radio';

export interface RadioButtonGroupProps extends RadioProps {
  options: GroupRadioOption[];
}

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const [value, setValue] = React.useState(props.value);

  const onChange = e => {
    const newValue = e.target.value;
    setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const antProps = { ...props, ...{ options: undefined } };

  return (
    <Radio.Group {...antProps} onChange={onChange} value={value}>
      {props.options.map((x: GroupRadioOption, i: number) =>
        <Tooltip title={x.tooltip} destroyTooltipOnHide mouseEnterDelay={.5} placement="top" key={i}>
          <RadioButton
            value={x.value}
            key={i}
            color={x.color}
            borderColor={x.borderColor}
            textColor={x.textColor}
            isChecked={value === x.value}
          >

            {x.label || x.value}
          </RadioButton>
        </Tooltip>)
      }
    </Radio.Group>
  );
};
