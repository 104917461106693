import { takeEvery, fork, call, all, put } from 'redux-saga/effects';
import appActions from './actions';
import { actions } from '../auth/actions';
import { fetchContentTypes } from '../../core/api/contentType';
import { getUserPreference, setUserPreference } from '../../helpers/store';

/* TODO in the future we might want to have a better way of booting up our application
 * one that calls every action in order and then calls the next one. instead of depending on us reading from
 * the redux state. this will be easier to maintain in the long term
 */

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ token }) {
    try {
      const response = yield call(fetchContentTypes);
      yield put(appActions.fetchContentTypeSuccess(response));
    } catch (e) {
      console.log(e);
    } finally {
      yield put(appActions.toggleLoading(false));
    }
  });
}

export function* fetchUnreadNotifications() {
  // TODO: I commented below until backend is ready. Uncomment once that happens.
  // yield takeEvery(appActions.FETCH_UNREAD_NOTIFICATIONS, function* () {
  //   try {
  //     const response = yield call(makeRequest, { url: Notification.UNREAD_NOTIFICATIONS, method: METHODS.GET });

  //     yield put(appActions.fetchUnreadNotificationsSuccess(response));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // });
}

export function* fetchPreferences() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ authUser }) {
    const { id } = authUser;
    const preference = getUserPreference(id);

    if (Object.keys(preference).length > 0) {
      yield put(appActions.getUserPreferenceSuccess(preference));
    } else {
      const userPreference = { userId: id, tablePreferences: {} };
      setUserPreference(userPreference);
      yield put(appActions.getUserPreferenceSuccess({ userId: id, tablePreferences: {} }));
    }
  });
}

export default function* rootSage() {
  yield all([
    fork(loginSuccess),
    fork(fetchPreferences),
    fork(fetchUnreadNotifications),
  ]);
}
