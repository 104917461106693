import { User } from 'core/entities/User';

export const actions = {
  CHECK_LOGGED_STATUS: 'CHECK_LOGGED_STATUS',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_WITH_ID_TOKEN_REQUEST: 'LOGIN_WITH_ID_TOKEN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',
  UPDATE_AUTH_USER: 'UPDATE_AUTH_USER',
  UPDATE_AUTH_USER_SUCCESS: 'UPDATE_AUTH_USER_SUCCESS',
  UPDATE_AUTH_USER_ERROR: 'UPDATE_AUTH_USER_ERROR',
};

export interface CheckLoggedStatus {
  type: typeof actions.CHECK_LOGGED_STATUS,
}

export interface LoginSuccess {
  type: typeof actions.LOGIN_SUCCESS,
  idToken: string,
  authUser: User,
}

export interface LoginRequest {
  type: typeof actions.LOGIN_REQUEST,
  email: string,
  password: string,
}

export interface LoginWithIdTokenRequest {
  type: typeof actions.LOGIN_WITH_ID_TOKEN_REQUEST,
  idToken: string,
}

export interface LogoutRequest {
  type: typeof actions.LOGOUT
}

export interface SignUpRequest {
  type: typeof actions.SIGN_UP_REQUEST,
  email: string,
  password: string,
}

export interface SignUpSuccess {
  type: typeof actions.SIGN_UP_SUCCESS,
}

export interface UpdateAuthUser {
  type: typeof actions.UPDATE_AUTH_USER,
  authUser: Partial<User>,
  currentPassword?: string,
  onSuccess?: (updatedAuthUser: User) => void,
  onFail?: (error: any) => void,
}

export interface UpdateAuthUserSuccess {
  type: typeof actions.UPDATE_AUTH_USER_SUCCESS,
  authUser: User,
}

const checkLoggedStatus = (): AuthActionsType => ({
  type: actions.CHECK_LOGGED_STATUS 
});

const loginRequest = (email: string, password: string): AuthActionsType => ({
  type: actions.LOGIN_REQUEST,
  email,
  password,
});

const loginWithIdTokenRequest = (idToken: string): AuthActionsType => ({
  type: actions.LOGIN_WITH_ID_TOKEN_REQUEST,
  idToken,
});

const loginSuccess = (idToken: string, authUser: User): AuthActionsType => ({
  type: actions.LOGIN_SUCCESS,
  idToken,
  authUser,
});

const loginFail = (): AuthActionsType => ({
  type: actions.LOGIN_FAIL
});

const signUpRequest = (email: string, password: string): AuthActionsType => ({
  type: actions.SIGN_UP_REQUEST,
  email,
  password,
});

const signUpSuccess = (): AuthActionsType => ({
  type: actions.SIGN_UP_SUCCESS,
});

const signUpFail = (): AuthActionsType => ({ 
  type: actions.SIGN_UP_FAIL
});

const logout = (): AuthActionsType => ({
  type: actions.LOGOUT,
});

const updateAuthUser = (
  authUser: Partial<User>,
  currentPassword?: string, 
  onSuccess?: (updatedAuthUser: User) => void,
  onFail?: (error: any) => void  
): AuthActionsType => ({
  type: actions.UPDATE_AUTH_USER,
  authUser,
  currentPassword,
  onSuccess,
  onFail,
});

const updateAuthUserSuccess = (authUser: Partial<User>): AuthActionsType => ({
  type: actions.UPDATE_AUTH_USER_SUCCESS,
  authUser
});

const updateAuthUserError = (): AuthActionsType => ({
  type: actions.UPDATE_AUTH_USER_ERROR
});

export const authActions = {
  checkLoggedStatus,
  loginRequest,
  loginWithIdTokenRequest,
  logout,
  loginSuccess,
  loginFail,
  signUpRequest,
  signUpSuccess,
  signUpFail,
  updateAuthUser,
  updateAuthUserSuccess,
  updateAuthUserError
};

export type AuthActionsType = CheckLoggedStatus
  | LoginRequest
  | LoginWithIdTokenRequest
  | LogoutRequest
  | LoginSuccess
  | SignUpRequest
  | SignUpSuccess
  | UpdateAuthUser
  | UpdateAuthUserSuccess
  ;
