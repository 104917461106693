import { copyExistingProps } from 'helpers/utility';
import { eInputType, FieldDefinition } from '../../components/DynamicForm';
import { eDataType } from './DataType';

export class UserPreferences {
  tablePreferences: Record<string, TablePreference[]> = {};
  userId: number = -1;

  /* eslint-disable @typescript-eslint/camelcase */
  addUserPreference({ table_name, ...rest }: TablePreference) {
    if (!this.tablePreferences[table_name]) {
      this.tablePreferences[table_name] = [];
    }

    this.tablePreferences[table_name] = this.tablePreferences[table_name].map(value => ({
      ...value,
      isSelected: false,
    }));

    this.tablePreferences[table_name].push({ table_name, ...rest });
  }

  deleteUserPreference({ table_name, preference_name }: TablePreference) {
    this.tablePreferences[table_name] = this.tablePreferences[table_name].filter(value =>
      value.preference_name !== preference_name,
    );
  }

  editTablePreference({ table_name, preference_name, checked_tabs }: TablePreference) {
    this.tablePreferences[table_name] = this.tablePreferences[table_name].map(value => {
        if (value.preference_name === preference_name) {
          return { ...value, checked_tabs, isSelected: true };
        }

        return { ...value, isSelected: false };
      },
    );
  }

  /* eslint-enable */

  constructor(obj: Partial<UserPreferences>) {
    copyExistingProps(this, obj);
  }
}

export class TablePreference {
  table_name: string = '';
  preference_name: string = '';
  checked_tabs: string = '';
  isSelected?: boolean = false;

  public static FORM_FIELDS: FieldDefinition<TablePreference>[] = [
    {
      key: 'preference_name',
      label: 'Name',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT,
      isRequired: true
    },
  ];

  constructor(obj: Partial<TablePreference>) {
    copyExistingProps(this, obj);
  }
}
