import { store } from './store';
import { authActions } from './auth/actions';
import packageJson from '../../package.json';

// @ts-ignore
global.appVersion = packageJson.version;

export default () => new Promise(() => {
  store.dispatch(authActions.checkLoggedStatus());
});
