import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export type notificationConfig = ArgsProps;

const showNotification = (type: ArgsProps['type'], config?: ArgsProps) => {
  const defaults = {duration: 30};
  // @ts-ignore
  notification[type]({...defaults, ...config});
};

export default showNotification;
