import React from 'react';
import { RouterProps } from 'react-router';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import App from './App/App';
import { Spinner } from './components/Spinner';
import asyncComponent from './helpers/AsyncFunc';

interface PublicRouterProps extends RouterProps {
  isLoggedIn: boolean;
  isLoading: boolean;
}

interface RestrictedRouteProps {
  component: React.ComponentType;
  computedMatch?: any;
  location?: object | any;
  path: string;
  isLoggedIn: boolean;
  [key: string]: any;
}

const RestrictedRoute = (props: RestrictedRouteProps) => {
  const { component: Component, isLoggedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PublicRouter = (props: PublicRouterProps) => {
  const { history, isLoggedIn, isLoading } = props;

  if (isLoading) return <Spinner />;

  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/signin"
            component={asyncComponent(() => import('./pages/SignIn/SignIn'))}
          />
          <Route
            exact
            path="/signup"
            component={asyncComponent(
              () => import('./pages/SignUpPage/SignUpPage')
            )}
          />
          <Route
            exact
            path="/logout"
            component={asyncComponent(() => import('./pages/Logout/Logout'))}
          />
          <Route
            exact
            path="/password-reset"
            component={asyncComponent(
              () => import('./pages/ForgotPassword/ForgotPassword')
            )}
          />
          <Route
            exact
            path="/password-reset/confirm/:uid/:token"
            component={asyncComponent(
              () => import('./pages/Recovery/Recovery')
            )}
          />
          <Route
            exact
            path="/register_confirm"
            component={asyncComponent(
              () => import('./pages/StaticPages/ConfirmEmail')
            )}
          />
          <Route
            exact
            path="/desktop-provider-sign-in/:provider/:encodedTimestamp/:desktopId"
            component={asyncComponent(
              () =>
                import('./pages/DesktopProvidersSignIn/DesktopProviderSignIn')
            )}
          />
          {/* 
              This route serves as the main entry point for URLs that require authentication
              (ie. coming from the Desktop app). It'll show the default DesktopMain page or,
              will redirect upon authentication to the redirectPath, if passed.
           */}
          <Route
            exact
            path="/desktop-main/:idToken/:redirectPath?"
            component={asyncComponent(
              () => import('./pages/DesktopMain/DesktopMain')
            )}
          />
          <Route
            exact
            path="/confirm_email/:uid/:token"
            component={asyncComponent(
              () => import('./pages/StaticPages/ConfirmedEmail')
            )}
          />
          <RestrictedRoute
            exact
            path="/welcome/"
            component={asyncComponent(
              () => import('./pages/Welcome/WorkspaceSetup')
            )}
            isLoggedIn={isLoggedIn}
          />
          <RestrictedRoute
            exact
            path="/welcome/workspace-setup"
            component={asyncComponent(
              () => import('./pages/Welcome/WorkspaceSetup')
            )}
            isLoggedIn={isLoggedIn}
          />
          <RestrictedRoute
            exact
            path="/welcome/download-app"
            component={asyncComponent(
              () => import('./pages/Welcome/DownloadApp')
            )}
            isLoggedIn={isLoggedIn}
          />
          <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />
        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  );
};

export default connect((state: any) => ({
  isLoggedIn: state.Auth.idToken !== null,
  isLoading: state.App.isLoading,
}))(PublicRouter);
