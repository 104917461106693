import Axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import parseDomain from 'parse-domain';
import { fireauth } from 'settings/firebase';
import { refreshAuthUserData } from '.';
import { getIdToken, getTenantUUID, setIdToken } from '../../helpers/store';


export const getDomain = () => {
  const parsedDomain = parseDomain(window.location.hostname, { customTlds: /localhost/ });
  if (process.env.NODE_ENV !== 'development' && parsedDomain) {
    const host = parsedDomain.domain;
    const subDomain = parsedDomain.subdomain;
    return { host, subDomain };
  } else {
    const host = 'localhost:3000';
    const subDomain = parsedDomain ? parsedDomain.subdomain : '';
    return { host, subDomain };
  }
};


const serverUrl = process.env.NODE_ENV === 'development' ? 'localhost:8000' : 'api.timeivy.com';
const scheme = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';

const API_VERSION = 'v1';
const ROUTE = 'api';

export const BASE_URL = scheme + serverUrl;
export const SERVER_URL = [BASE_URL, ROUTE, API_VERSION].join('/');

// Axios config.
Axios.defaults.baseURL = `${SERVER_URL}`;
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.interceptors.request.use(function (config) {
  // Do something before request is sent.
  return config;
}, function (error) {
  handleAxiosError(error);
  return Promise.reject(error);
});
Axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data.
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger.
  handleAxiosError(error);
  return Promise.reject(error);
});

function handleAxiosError(error: AxiosError) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx.
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error.
    console.error('Error', error.message);
  }
  console.log(error.config);
}

export enum METHODS {
  GET = 'get',
  POST = 'post',
  UPDATE = 'update',
  DELETE = 'delete',
  PATCH = 'patch',
}

export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post',
  UPDATE = 'update',
  DELETE = 'delete',
  PATCH = 'patch',
}

export type QueryParams = { [key: string]: string | number | boolean };

export interface RequestConfig extends AxiosRequestConfig {
  id?: number,
  queryParams?: QueryParams,
  shouldThrow?: boolean,
}

function buildQuery(requestConfig: RequestConfig) {
  const { id = '', url = '', queryParams = {} } = requestConfig;
  const urlParams = id ? `${id}/` : '';
  const queryParamsArray = Object.keys(queryParams).map(element => `${element}=${encodeURIComponent(queryParams[element])}`);
  const queryParamsUrl = queryParamsArray.length > 0 ? `?${queryParamsArray.join('&')}` : '';
  return `${url}/${urlParams}${queryParamsUrl}`;
}

export async function makeRequest<T>(requestConfig: RequestConfig): Promise<any> {
  const { data, method, baseURL, shouldThrow = true } = requestConfig;
  const url = buildQuery(requestConfig);

  // Make sure we get a non-expired token, otherwise the server
  // would return an unauthorized response.
  const authUserData = await refreshAuthUserData();
  if (!authUserData) {
    return null;
  }
  const { idToken, tenantUUID } = authUserData;

  const config: AxiosRequestConfig = {
    // @ts-ignore
    method, url, data, baseURL,
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Tenant-UUID': tenantUUID,
    },
  };

  try {
    const response = await Axios.request(config);
    return response.data as T;
  } catch (err) {
    console.error('"makeRequest()" error:', err);
    if (shouldThrow) {
      return Promise.reject(err);
    }
    return null;
  }
}

// TODO: IS THIS NEEDED?
export async function makeSimpleRequest(url: string, method: Method) {
  let idToken = getIdToken();
  const tenantUUID = getTenantUUID();

  // Token expired. Get a new one from firebase before making a request
  // to the server, which would return unauthorized otherwise.
  if (!idToken) {
    if (fireauth.currentUser) {
      idToken = await fireauth.currentUser.getIdToken(true);
      setIdToken(idToken);
    }
  }

  const { data } = await Axios.request({
    url,
    method,
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Tenant-UUID': tenantUUID,
    },
  });

  return data;
}
