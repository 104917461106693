import React, { Component } from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { actions } from 'redux/popOutContent/actions';

const { hideContent } = actions;

class DynamicDrawerConnected extends Component<any> {
  drawerBodyStyle = {
    overflow: 'auto',
    height: 'calc(100vh - 100px)',
    'padding': 0,
  };

  handleClose = () => {
    this.props.onCancel();
    this.props.hideContent();
  };

  render() {
    const { Content, title, target = 'sidebar', isVisible } = this.props;

    return (
      <div>
        <Drawer
          title={title}
          width={500}
          onClose={this.handleClose}
          destroyOnClose
          bodyStyle={this.drawerBodyStyle}
          visible={target === 'sidebar' && isVisible}
        >
          <Content/>
        </Drawer>
      </div>
    );
  }
}

export const DynamicDrawer = connect(
  (state: any) => ({ ...state.popOutContent }),
  { hideContent },
)(DynamicDrawerConnected);

