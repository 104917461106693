import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from '../../components/DynamicForm';
import { ColumnConfig } from '../../components/DynamicTable';
import { copyExistingProps } from '../../helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class FacilityCategory extends BaseEntity {
  static NAME = 'category';
  static CONTENT_TYPE_NAME = 'facilitycategory';
  static END_POINT = 'assets/facility_categories';

  @IsNotEmpty()
  @MaxLength(50)
  name = '';

  @MaxLength(10)
  code = '';
  parent?: number = undefined;

  static FORM_FIELDS: FieldDefinition<FacilityCategory>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    { key: 'code', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    {
      key: 'parent',
      dataType: eDataType.NUMBER,
      inputType: eInputType.SELECT,
      fieldOptions: {
        endpoint: 'assets/facility_categories',
        optionLabel: ({ code, name }: any) => `${name} ${code ? `- ${code}` : ''}`,
      },
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'code' },
  ];

  constructor(obj: Partial<FacilityCategory>) {
    super();

    copyExistingProps(this, obj);
  }
}
