import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from '../../components/DynamicTable';
import { copyExistingProps } from '../../helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class WorkRequest extends BaseEntity {
  static END_POINT = 'work/work_requests';
  static NAME = 'work request';
  static CONTENT_TYPE_NAME = 'workrequest';
  @IsNotEmpty()
  @MaxLength(200)
  name = '';

  @MaxLength(2048)
  description = '';

  priority?: 'low' | 'medium' | 'high' = undefined;

  requester?: number = undefined;

  static FORM_FIELDS: FieldDefinition<WorkRequest>[] = [
    { key: 'name', isRequired: true, inputType: eInputType.TEXT },
    { key: 'description', inputType: eInputType.TEXT_AREA },
    {
      key: 'priority',
      inputType: eInputType.GROUP_RADIO_BUTTON,
      dataType: eDataType.STRING,
      fieldOptions: {
        options: [
          { value: 'low', color: ' #ebc034' },
          { value: 'medium', color: '#eb9634' },
          { value: 'high', color: '#eb5c34' },
        ],
      },
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'requester_name' },
  ];

  constructor(o: Partial<WorkRequest>) {
    super();

    copyExistingProps(this, o);
  }
}
