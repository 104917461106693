import { idToken$ } from 'settings/firebase';
import { ID_TOKEN } from './constants';


export function getIdToken(): string | null {
  const idToken = idToken$.value || localStorage.getItem(ID_TOKEN);
  return !hasIdTokenExpired(idToken) ? idToken : '';
}

export function setIdToken(idToken: string): void {
  localStorage.setItem(ID_TOKEN, idToken);
}

export function deleteIdToken(): void {
  localStorage.removeItem(ID_TOKEN);
}

export function hasIdTokenExpired(idToken: string | null): boolean {
  if (!idToken) return true;

  const base64DecodedIdToken = atob(idToken.split('.')[1]);

  try {
    // Get the expiration from the firebase idToken itself.
    const expirationTimeInSecondsSinceEpoch = JSON.parse(base64DecodedIdToken).exp;
    if (expirationTimeInSecondsSinceEpoch == null) {
      return true;
    }

    const currentTimeInSecondsSinceEpoch = Math.round(new Date().getTime() / 1000);
    const minutesToExpiration = (expirationTimeInSecondsSinceEpoch - currentTimeInSecondsSinceEpoch) / 60;

    // Leave at least 5 mins of threshold.
    return minutesToExpiration < 5;

  } catch (e) {
    console.warn(`JSON.Parse error when parsing "idToken": ${e}`);
    return true;
  }
}
