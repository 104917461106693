import React from 'react';
import { Helmet } from 'react-helmet';
import { HelmetContext, IHelmetContext } from 'context/HelmetContext';
import { withRouter } from 'react-router';
import { siteConfig } from '../../settings';

export const AppHelmet: React.ComponentType = withRouter((props) => {
  const path = props.location.pathname;
  const capitalize = (name: string) => {
    return name
      .split('-')
      .map(([first, ...rest]) => {
        return `${first.toUpperCase()}${rest.join('')}`;
      })
      .join(' ');
  };

  const pathTitle = path
    .split('/')
    .filter((value) => value)
    .map((value) => capitalize(value))
    .reverse()
    .join(' - ');

  const context = React.useContext(HelmetContext) as IHelmetContext;
  const { shouldUseContextTitle, title } = context.state;
  const pageTitle = shouldUseContextTitle
    ? title
    : `${'Home'} - ${siteConfig.siteName}`;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      {/*<link rel="canonical" href="http://mysite.com/example"/>*/}
    </Helmet>
  );
});
