const siteConfig = {
  siteName: 'TimeIvy',
  siteIcon: 'ion-flash',
  footerText: new Date().getFullYear() + ' ©TimeIvy',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const language = 'english';

export {
  siteConfig,
  language,
  themeConfig
};
