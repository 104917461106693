import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    .ant-spin-spinning {
        position: absolute;
        top: 40%;
    }
`;

export default Container;
