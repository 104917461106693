import { User } from 'core/entities/User';
import { actions, AuthActionsType, LoginSuccess, UpdateAuthUserSuccess } from './actions';

interface AuthState {
  idToken: string | null
  authUser?: User | null
}

const initState: AuthState = { idToken: null };

export default function authReducer(state = initState, action: AuthActionsType | any): AuthState {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      const actionLoginSuccess: LoginSuccess = action;
      return {
        ...state,
        idToken: actionLoginSuccess.idToken,
        authUser: new User(actionLoginSuccess.authUser)
      };
    case actions.UPDATE_AUTH_USER_SUCCESS:
      const actionUpdateAuthUser: UpdateAuthUserSuccess = action;
      return {
        ...state,
        authUser: actionUpdateAuthUser.authUser
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
