import { cloneDeep } from 'lodash';
import { Button } from 'components/general';
import { CheckboxSelect } from '@atlaskit/select';
import React, { RefObject } from 'react';
import ReactTable, { TableProps, CellInfo } from 'react-table-6';
import 'react-table-6/react-table.css';
import { capitalizeSnakeCase } from 'helpers/utility';
import { TablePreference } from 'core/entities';
import { ColumnConfig, HeaderConfig, SelectFieldConfig } from '.';
import { makeRequest, METHODS, QueryParams } from '../../core/api/httpClient';
import { SearchInput } from '../SearchInput';
import Table from './DynamicTable.style';
import { ViewSelector } from './Components/ViewSelector';
import { SelectField } from 'components/DynamicForm';
import entityClient from 'core/api/entityClient';
import { showNotification } from 'components';
import { Row, Col } from 'components/layout';
import { PlusOutlined } from '@ant-design/icons';

export interface DynamicTableProps {
  columnConfig?: ColumnConfig[];
  imageKeys?: string[];
  onRowClick: (id?: number) => void;
  onFetchData?: (data: any[]) => void;
  endpoint: string;
  queryParams?: QueryParams;
  showSearchBar?: boolean;
  showCreateButton?: boolean;
  showColumnChooser?: boolean;
  showViewSelector?: boolean;
  tableName: string;
  defaultPageSize?: number;
  entityClass: ModelConstructor<any>;
  style?: string;
  reactTableProps?: Partial<TableProps>;
}

interface State {
  columns: ColumnConfig[];
  originalColumns: ColumnConfig[];
  currentColumns: string;
  allSelected: string;
  selectAll: ColumnConfig;
  data: any[];
  loading: boolean;
  pages: number;
  search: string;
}

export class DynamicTable extends React.PureComponent<
  DynamicTableProps,
  State
> {
  static defaultProps = {
    defaultPageSize: 50,
  };
  table: RefObject<ReactTable<any>> = React.createRef();

  constructor(props: DynamicTableProps) {
    super(props);
    const selectAll: ColumnConfig = {
      key: '*',
      accessor: '*',
      Header: 'Uncheck All',
      show: false,
      order: -1,
    };
    const [columns, allSelected] = this.getColumns();

    this.state = {
      columns: [selectAll, ...columns],
      selectAll,
      originalColumns: columns,
      data: [],
      allSelected,
      currentColumns: allSelected,
      loading: false,
      pages: 0,
      search: '',
    };
  }

  getDefaultImageCell = (cell: CellInfo): React.ReactElement => (
    <div style={{ width: '20px', overflow: 'hidden' }}>
      <img src={cell.value} alt="" style={{ height: '20px', width: 'auto' }} />
    </div>
  );

  getSelectFieldCell = (
    cell: CellInfo,
    selectFieldConfig: SelectFieldConfig
  ): React.ReactElement => {
    const { original, index, column } = cell;
    const propertyName = column.id as string;

    selectFieldConfig.value = original[propertyName];

    selectFieldConfig.onChange = (value: number | string) => {
      const item = cloneDeep(original);
      item[propertyName] = value;

      if (selectFieldConfig.onBeforeSave) {
        selectFieldConfig.onBeforeSave(cell, item);
      }

      this.saveRowItem(item, original, index).then((savedItem) => {
        if (selectFieldConfig.onAfterSave) {
          selectFieldConfig.onAfterSave(cell, savedItem);
        }
      });
    };

    const styles = {
      control: (base) => ({
        ...base,
        backgroundColor: 'transparent',
        ':hover': { ...base[':hover'], backgroundColor: '#fff' },
        border: 'none',
        minHeight: 20,
      }),
      ...selectFieldConfig.styles,
    };

    return (
      <SelectField
        {...selectFieldConfig}
        key={original[propertyName]}
        className="select-field"
        id={`select-field-${index}`}
        // @ts-ignore
        spacing="compact"
        styles={styles}
      />
    );
  };

  getColumns = (): [ColumnConfig[], string] => {
    const { columnConfig = [] } = this.props;
    let currentColumns = '';

    const config = columnConfig.reduce((acc, keyConfig, index): any => {
      if (keyConfig.shouldHide) return acc;

      const {
        key,
        accessor,
        Header,
        isImage: isImageCell,
        cellRenderer,
        isClickable,
        label,
        selectFieldConfig,
      } = keyConfig;

      const column: HeaderConfig = {
        ...keyConfig,
        accessor: accessor || key,
        Header: Header || label || capitalizeSnakeCase(key),
        order: index,
        headerStyle: { zIndex: 0 },
      };

      if (isImageCell) {
        column.Cell = this.getDefaultImageCell;
      }

      if (selectFieldConfig) {
        column.Cell = (cell: CellInfo) =>
          this.getSelectFieldCell(cell, selectFieldConfig);
        column.style = { overflow: 'visible' }; // Fixes cut-off dropdown
      }

      if (isClickable) {
        column.className = 'clickable';
      }

      if (cellRenderer) {
        column.Cell = cellRenderer;
      }

      currentColumns += `${key} `;

      return [...acc, column];
    }, []);

    return [config, currentColumns];
  };

  handleCheckedRowsChange = (selected: HeaderConfig[], action: any) => {
    const { selectAll } = this.state;
    if (action.option.accessor === selectAll.key) {
      switch (action.action) {
        case 'deselect-option':
          selectAll.Header = 'Check All';
          this.setState({ columns: [], selectAll, currentColumns: '' });
          break;
        case 'select-option':
          selectAll.Header = 'Uncheck All';
          this.setState((prevState) => {
            return {
              columns: prevState.originalColumns,
              selectAll,
              currentColumns: prevState.allSelected,
            };
          });
          break;
        default:
          break;
      }
    } else {
      let selectedColumns = '';
      const ordered = selected.sort((a, b) => {
        return a.order - b.order;
      });

      ordered.map((column) => (selectedColumns += `${column.key} `));

      this.setState({ columns: ordered, currentColumns: selectedColumns });
    }
  };

  handleCreateButtonClick = (e: any) => {
    this.props.onRowClick();
  }

  handleViewChange = (view: TablePreference) => {
    const { originalColumns, selectAll } = this.state;
    const newColumns = originalColumns.filter((column) => {
      // @ts-ignore
      return view.checked_tabs.includes(column.key);
    });

    this.setState({
      columns: [selectAll, ...newColumns],
      currentColumns: view.checked_tabs,
    });
  };

  saveRowItem = async (
    item: object,
    originalItem: object,
    indexInData: number
  ) => {
    const savedItems = await entityClient.updateEntities(
      this.props.entityClass,
      [item]
    );
    const newData = [...this.state.data];

    if (savedItems.length) {
      // Try to preserve the full object. If we just replace it, a patch or removing
      // properties before the save would set it as a partial on the table.
      newData[indexInData] = Object.assign(originalItem, savedItems[0]);

      this.setState({ data: newData }, () => {
        if (this.props.onFetchData) {
          this.props.onFetchData(newData);
        }
      });
      return newData[indexInData];
    } else {
      // TODO: Re-render the grid so it shows again the item as it was before.
      showNotification('error', { message: 'Failed to save.' });
      newData[indexInData] = originalItem;
      Promise.reject();
    }
  };

  fetchData = async (state: any) => {
    this.setState({ loading: true });

    const { pageSize, page, sorted } = state;
    const { search } = this.state;

    /**
     * this gets the sort property from the table state, then saves it in ordering.
     * if the column has a defined orderingField property, then it will use those instead of the property name
     * this will be sent in the request as queryparam
     */
    const ordering = sorted.reduce((acc: string, { id, desc }: any) => {
      const column = this.state.columns.find(({ key }) => key === id);
      if (column && column.orderingFields) {
        return (
          acc +
          column.orderingFields
            .map((field) => `${desc ? '-' : ''}${field}`)
            .join(',')
        );
      }
      return acc + `${desc ? '-' : ''}${id}`;
    }, '');

    const response: any = await makeRequest({
      url: this.props.endpoint,
      method: METHODS.GET,
      queryParams: {
        ordering,
        search,
        offset: page * pageSize,
        limit: pageSize,
        ...this.props.queryParams,
      },
    });

    if (!response) {
      this.setState({
        loading: false,
      });
      return;
    }

    const data = response.results;
    const count = response.count;
    const pages = Math.ceil(count / pageSize);

    this.setState(
      {
        loading: false,
        data,
        pages,
      },
      () => {
        if (this.props.onFetchData) {
          this.props.onFetchData(data);
        }
      }
    );
  };

  refreshTable = () => {
    if (this.table.current) {
      (this.table.current as any).updater.enqueueSetState(this.table.current, {
        page: 0,
      });
      this.fetchData(this.table.current.state);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.refreshTable();
    }
  }

  onSearchChange = (search: string) => {
    this.setState({ search: search }, this.refreshTable);
  };

  render() {
    const {
      showSearchBar,
      showCreateButton: showAddButton,
      showViewSelector,
      showColumnChooser,
      tableName,
      defaultPageSize,
      reactTableProps,
    } = this.props;
    const { columns, originalColumns, data, currentColumns, loading, pages } =
      this.state;

    if (!columns) return null;
    return (
      <Table>
        <div className="actions">
          {showColumnChooser && (
            <CheckboxSelect
              className="checkbox-select"
              classNamePrefix="select"
              onChange={this.handleCheckedRowsChange}
              options={originalColumns}
              value={columns}
              placeholder="Column Chooser"
              getOptionValue={(option: any) => option.accessor}
              getOptionLabel={(option: any) => option.Header}
              isClearable={false}
              controlShouldRenderValue={false}
            >
              <Button
                className="button"
                type="primary"
                onClick={this.props.onRowClick as any}
              >
                New
              </Button>
            </CheckboxSelect>
          )}

          {showViewSelector && (
            /* @ts-ignore */
            <ViewSelector
              tableName={tableName}
              tableViewChanged
              onViewLoaded={this.handleViewChange}
              currentColumns={currentColumns}
              originalColumns={originalColumns}
            >
              Dropdown
            </ViewSelector>
          )}
        </div>

        <Row>
          <Col xs={24} sm={12} xl={12}>
            {showSearchBar && <SearchInput onNewData={this.onSearchChange} />}
          </Col>
          <Col xs={24} sm={12} xl={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
            {showAddButton && <Button type="primary" icon={<PlusOutlined />} onClick={this.handleCreateButtonClick}>New</Button>}
          </Col>
        </Row>

        <ReactTable
          manual
          ref={this.table}
          pages={pages}
          loading={loading}
          data={data}
          columns={columns}
          minRows={1}
          noDataText="No items found"
          className="-no-side-borders -highlight" // -striped
          defaultPageSize={defaultPageSize}
          onFetchData={this.fetchData} // Request new data when things change
          getTdProps={(state: any, rowInfo: any, column: any) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (handleOriginal) {
                  if (column.isClickable) {
                    this.props.onRowClick(rowInfo.original.id);
                  }
                  handleOriginal();
                }
              },
            };
          }}
          {...reactTableProps}
        />
      </Table>
    );
  }
}
