import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';

const logo = require(`image/timeivy-logo-white.png`);
const leaf = require(`image/timeivy-leaf-green.png`);


export default ({ collapsed, onClick }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <Link to="/" onClick={onClick}>
          <img
              src={process.env.PUBLIC_URL + leaf}
              alt={siteConfig.siteName}
              style={{ height: 28, marginTop: 15, marginLeft: 3 }}
            />
          </Link>

        </div>
      ) : (
        <h3>
          <Link to="/" onClick={onClick}>
            <img
              src={process.env.PUBLIC_URL + logo}
              alt={siteConfig.siteName}
              style={{ height: 36 }}
            />
          </Link>
        </h3>
      )}
    </div>
  );
};
