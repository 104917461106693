import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { SwitchableDatePickerField, SwitchableDatePickerFieldProps, TimeUnit } from 'components';


interface PastDateRangePickerFieldProps extends Omit<SwitchableDatePickerFieldProps, 'onChange' | 'picker'> {
  timeUnit?: TimeUnit;
  style?: object,
  onChange: (dateFrom: string, dateTo: string) => void,
  onPickerTypeChange?: (selectedUnit: TimeUnit) => void,
}

/**
 * Single picker with arrows date-range input that allows only today or past
 * dates to be selected.
 */
export function PastDateRangePickerField(props: PastDateRangePickerFieldProps) {
  const { onChange, onPickerTypeChange, timeUnit, ...rest } = props;

  const getPickerFromTimeUnit = (timeUnit?: TimeUnit): SwitchableDatePickerFieldProps['picker'] => {
    if (timeUnit === 'day') {
      return 'date';
    }
    return timeUnit;
  }

  const handleChange = (
    value: Dayjs | null,
    dateString: string,
    unit: TimeUnit
  ) => {
    if (value) {
      if (onChange) {
        const dateFrom = value.startOf(unit).format();
        const dateTo = value.endOf(unit).format();
        onChange(dateFrom, dateTo);
      }
    }
  }

  const handlePickerTypeChange = (unit: TimeUnit) => {
    handleChange(dayjs(), '', unit);
    if (onPickerTypeChange) {
      onPickerTypeChange(unit);
    }
  }

  const isRightButtonDisabled = (currentValue, unit: TimeUnit) => {
    return currentValue && currentValue.isSame(dayjs(), unit);
  }

  const isDateDisabled = (currentValue) => {
    // Disable future dates.
    return currentValue && currentValue > dayjs().endOf('day');
  }

  return (
    <SwitchableDatePickerField
      {...rest}
      picker={getPickerFromTimeUnit(timeUnit)}
      onChange={handleChange}
      onPickerTypeChange={handlePickerTypeChange}
      allowClear={false}
      disabledRightButton={isRightButtonDisabled}
      disabledDate={isDateDisabled}
    />
  );
}
