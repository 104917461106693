import React from 'react';
import { Spin, SpinProps } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import Container from './Spinner.style';

const loadingIcon = <Loading3QuartersOutlined style={{ fontSize: 24 }} spin />;


export function Spinner(props: SpinProps) {
  return (
    <Container>
      <Spin indicator={loadingIcon} {...props} />
    </Container>
  );
}
