import { Modal } from 'antd';

export function showConfirm(onOkPressed: Function, entityName: string) {
  Modal.error({
    title: `Do you want to delete this ${(entityName || '').toLowerCase()}?`,
    onOk() {
      return onOkPressed()
    },
    okCancel: true,
    okType: 'danger',
    maskClosable: true
  });
}
