import React from 'react';
import { TargetType } from 'helpers/entities';
import { actions } from './actions';

interface PopOutContentState {
  isVisible: boolean,
  Content: React.ComponentType,
  onCancel: Function,
  title: string,
  target: TargetType
}

const initState: PopOutContentState = {
  isVisible: false,
  Content: () => null,
  onCancel: Function,
  title: '',
  target: 'sidebar',
};

interface Action {
  type: string

  [key: string]: any
}

export default function popOutContentReducer(state = initState, action: Action) {
  switch (action.type) {
    case actions.SET_TITLE:
      return { ...state, title: action.title };
    case actions.SET_ON_CANCEL:
      return { ...state, onCancel: action.onCancel };
    case actions.SET_CONTENT:
      const title = action.title || state.title;
      const target = action.target || state.target;
      return { ...state, Content: action.content, title, target };
    case actions.HIDE_POPOUT_CONTENT:
      return { ...state, isVisible: false };
    case actions.SHOW_POPOUT_CONTENT:
      return { ...state, isVisible: true };
    default:
      return state;
  }
}
