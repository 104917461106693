import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { store, history } from './redux/store';
import PublicRouter from './router';
import themes from './settings/themes';
import AppLocale from './redux/languageProvider';
import config, {
  getCurrentLanguage,
} from './pages/LanguageSwitcher/config';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import CacheBuster from './components/CacheBurster/CacheBurster';
import { RefreshButton } from './components/CacheBurster/RefreshBanner';
import { Spinner } from './components/Spinner';


const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

// let validEndpoint = undefined;
const DashApp = () => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return <Spinner/>;

      return (
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            {/*{validEndpoint ?*/}
            <ThemeProvider theme={themes[themeConfig.theme]}>
              <DashAppHolder>
                <Provider store={store}>
                  <React.Fragment>
                    {/* {!isLatestVersion && !loading &&
                    <div className="refresh-div"><p>There is a new update available.</p><RefreshButton
                      onClick={refreshCacheAndReload}/></div>} */}
                    <PublicRouter history={history}/>
                  </React.Fragment>
                </Provider>
              </DashAppHolder>
            </ThemeProvider>
            {/*: <NotFound/>*/}
            {/*}*/}
          </IntlProvider>
        </ConfigProvider>
      );
    }}
  </CacheBuster>
);

Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
