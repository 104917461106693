import React, { ChangeEvent } from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import { getLatLong } from 'core/api';
import 'ol/ol.css';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import Map from 'ol/Map.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Icon, Style } from 'ol/style.js';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import View from 'ol/View.js';
import Button from '@atlaskit/button';
import { GPS_Location } from 'core/entities';
import { ErrorMessage } from './MapField.style';

const icon = require('image/marker-icon.png');

interface Props {
  address?: string
  onChange: Function
  value?: GPS_Location
}

interface State {
  isChecked: boolean,
  setLocationOnMap: boolean,
  prevAddress?: string
  showErrorMessage: boolean;
}

export class MapField extends React.Component<Props, State> {
  mapDivId: string;
  map: Map | undefined;
  vectorLayer?: VectorLayer;

  iconStyle = new Style({
    image: new Icon(({
      anchor: [0.5, 46],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.PIXELS,
      src: icon,
    })),
  });

  constructor(props: Props) {
    super(props);

    this.mapDivId = `map-field`;
    const location = props.value;
    const isChecked = !!location;

    this.state = {
      prevAddress: props.address,
      setLocationOnMap: false,
      showErrorMessage: false,
      isChecked,
    };
  }

  getIcon = (longitude: number, latitude: number) => {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude])),
    });

    iconFeature.setStyle(this.iconStyle);

    const vectorSource = new VectorSource({
      features: [iconFeature],
    });

    return new VectorLayer({
      source: vectorSource,
    });
  };

  componentDidMount(): void {
    const location = this.props.value;
    if (location) {
      this.setupMap(location.longitude, location.latitude);
    }
  }

  setupMap = (longitude: number, latitude: number, zoom = 16) => {
    const vectorLayer = this.getIcon(longitude, latitude);
    const rasterLayer = new TileLayer({
      source: new OSM(),
    });

    const map = new Map({
      layers: [rasterLayer, vectorLayer],
      view: new View({
        center: fromLonLat([longitude, latitude]),
        zoom,
      }),
    });

    map.on('click', (e) => {
      if (!map || !this.state.setLocationOnMap) return;

      const iconFeature = new Feature({
        geometry: new Point(e.coordinate),
      });

      iconFeature.setStyle(this.iconStyle);

      const [longitude, latitude] = toLonLat(e.coordinate);

      this.vectorLayer && this.vectorLayer.getSource().clear();
      this.vectorLayer && this.vectorLayer.getSource().addFeature(iconFeature);

      this.props.onChange({ longitude, latitude });
    });

    this.vectorLayer = vectorLayer;
    this.map = map;

    this.map.setTarget(this.mapDivId);
  };

  handleButtonPress = () => {
    const { address } = this.props;

    this.setState({ prevAddress: address, showErrorMessage: !address });

    if (!address) {
      return;
    }

    getLatLong(address).then((response) => {
      if (!response) {
        this.setState({ showErrorMessage: true });
        return;
      }

      if (this.map) {
        const { Latitude: latitude, Longitude: longitude } = response;
        const map = this.map;

        map.removeLayer(this.vectorLayer!);
        this.getIcon(longitude, latitude);
        this.vectorLayer = this.getIcon(longitude, latitude);

        map.addLayer(this.vectorLayer);
        map.getView().setCenter(fromLonLat([longitude, latitude]));

        this.props.onChange({ longitude, latitude });
      }
    });
  };

  handleCheckPress = async (event: any) => {
    const isChecked = event.currentTarget.checked;
    const { address } = this.props;

    this.setState({ isChecked, prevAddress: address });

    if (!isChecked) return;

    // this is canada lat long. in the future this will change
    let latLong = { latitude: 43.651070, longitude: -79.347015 };
    const response = await getLatLong(address);

    if (response) {
      const { Latitude: latitude, Longitude: longitude } = response;
      latLong = { latitude, longitude };
    }

    this.setState({ showErrorMessage: !response });

    const zoom = address ? undefined : 4;
    const { latitude, longitude } = latLong;
    this.setupMap(longitude, latitude, zoom);
    this.props.onChange({ longitude, latitude });
  };

  handleChangeLocationChecked = (checked: ChangeEvent<HTMLInputElement>) =>
    this.setState({ setLocationOnMap: checked.target.checked });

  render() {
    const { address } = this.props;
    const { isChecked, prevAddress, showErrorMessage } = this.state;

    return (
      <React.Fragment>
        <Checkbox isChecked={isChecked} onChange={this.handleCheckPress} label="Show Map"/>
        {isChecked && <React.Fragment>
          {showErrorMessage &&
          <ErrorMessage>Address not found.</ErrorMessage>}
          <Checkbox
            onChange={this.handleChangeLocationChecked}
            label="Set location by clicking on map"
          />
          <div
            id={this.mapDivId}
          />
          <Button onClick={this.handleButtonPress} isDisabled={address === prevAddress}>Refresh</Button>
        </React.Fragment>}
      </React.Fragment>
    );
  }
}
