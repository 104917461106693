import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';

interface Props {
  id?: number,
  style?: {},
  children?: any,
  className?: string,
}

export default ({ id, style, children, className }: Props) => (
  <Scrollbar
    id={id}
    className={className}
    style={style}
    continuousScrolling={true}
  >
    {children}
  </Scrollbar>
);
