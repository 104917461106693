import { IsNotEmpty, MaxLength } from 'class-validator';
import { FieldDefinition, ColumnConfig } from 'components';
import { eInputType } from 'components/DynamicForm';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Facility extends BaseEntity {
  static NAME = 'facility';
  static CONTENT_TYPE_NAME = 'facility';
  static END_POINT = 'assets/facilities';

  @MaxLength(255)
  @IsNotEmpty()
  name = '';

  @MaxLength(255)
  address = '';
  gps_location?: GPS_Location = undefined;
  location = 0;
  facility_category?: number = undefined;

  static FORM_FIELDS: FieldDefinition<Facility>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    { key: 'address', dataType: eDataType.STRING, inputType: eInputType.TEXT_AREA },
    { key: 'gps_location', dataType: eDataType.OBJECT, inputType: eInputType.MAP_VIEW, accessor: 'address' },
    {
      key: 'facility_category',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: {
        endpoint: 'assets/facility_categories',
        optionLabel: ({ code, name }: any) => `${name} - ${code}`,
      },
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'address' },
  ];

  constructor(obj: Partial<Facility>) {
    super();

    copyExistingProps(this, obj);
  }
}

export interface GPS_Location {
  id: number
  latitude: number
  longitude: number
}
