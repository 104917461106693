/* eslint-disable @typescript-eslint/camelcase */
import Axios from 'axios';
import { ContentType, EntityFile } from 'core/entities';
import { makeRequest, METHODS, SERVER_URL } from 'core/api/httpClient';
import { getIdToken } from 'helpers/store';
import { store } from 'redux/store';
import { getEntities } from './form';

export async function getFiles(objectId: number, className: string) {
  const contentType: ContentType = store.getState().App.contentTypes[className];
  if (!contentType) return null;
  const id: string = contentType.id.toString(10);

  return await makeRequest({
    url: 'file_management/files',
    method: METHODS.GET,
    queryParams: { object_id: objectId, content_type: id, type: 'file' },
  });
}


export async function getImages(objectId: number, className: string) {
  const contentType: ContentType = store.getState().App.contentTypes[className];
  if (!contentType) return null;
  const id: string = contentType.id.toString(10);

  return getEntities('file_management/files', { object_id: objectId, type: 'image', content_type: id });
}

export async function saveImage(file: EntityFile) {
  const idToken = getIdToken();
  const { name, content_type, object_id, uploaded_by } = file;

  const data = new FormData();

  if (typeof file.file !== 'string') data.append('file', file.file as Blob);

  data.append('name', name);
  data.append('content_type', content_type.toString());
  data.append('object_id', object_id.toString());
  data.append('uploaded_by', uploaded_by.toString());

  const method = file.id ? Axios.patch : Axios.post;

  return await method(`file_management/files/${file.id ? file.id + '/' : ''}`, data, {
    baseURL: SERVER_URL,
    headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' },
  });
}

export async function deleteFile(id: number) {
  return makeRequest({
    url: `file_management/files/${id}`,
    method: METHODS.DELETE,
  });
}
