import React from 'react';
import { Row as AntRow, RowProps } from 'antd';
import RowStyle from './row.style';
import theme from 'settings/themes/themedefault';

const { gutter } = theme.row;


export const Row = RowStyle((props: RowProps) =>
  <AntRow gutter={gutter} {...props}>
    {props.children}
  </AntRow>
);