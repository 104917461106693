import { Spinner } from 'components';
import React from 'react';
import MainStyle from './main.style';

export interface MainProps {
  isLoading?: boolean;
  /**
   * If true, children will not be render while loading.
   * If false, they'll be displayed behind a full main overlay.
   **/
  hideChildrenWhileLoading?: boolean;
  children?: any;
  showOverlay?: boolean;
}

export const Main = (props: MainProps) => {
  const {
    isLoading,
    hideChildrenWhileLoading,
    children,
    showOverlay = true,
  } = props;

  const renderChildren = () => {
    if (hideChildrenWhileLoading) {
      return isLoading ? <Spinner /> : children;
    } else {
      return (
        <>
          {isLoading && showOverlay && (
            <div className="overlay">
              <Spinner />
            </div>
          )}
          {children}
        </>
      );
    }
  };

  return <MainStyle {...props}>{renderChildren()}</MainStyle>;
};
