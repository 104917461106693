import { IsEmail, IsNotEmpty } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from 'components/DynamicTable';
import { eDataType } from './DataType';
import { BaseEntity } from './BaseEntity';

export class WorkspaceInvitation extends BaseEntity {
  static NAME = 'WorkspaceInvitation';
  static CONTENT_TYPE_NAME = 'workspaceinvitation';
  static END_POINT = 'customers/workspace_invitations';

  @IsNotEmpty()
  @IsEmail()
  to_email!: string;

  // False once the invited email becomes a registered user of the workspace
  // or the inviter decides to revoke the invitation.
  readonly active!: boolean;
  readonly from_user!: number;
  readonly to_workspace!: number;

  static columnConfig: ColumnConfig[] = [
    { key: 'to_email', label: 'Email', isClickable: true },
  ];

  static FORM_FIELDS: FieldDefinition<WorkspaceInvitation>[] = [
    {
      key: 'to_email',
      label: 'Email',
      dataType: eDataType.STRING, 
      inputType: eInputType.TEXT,
      isRequired: true,
    },
  ];

}
