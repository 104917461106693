import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload } from 'antd';
import { saveImage } from 'core/api/files';
import IntlMessages from 'components/utility/intlMessages';
import { EntityFile } from 'core/entities';

import 'antd/dist/antd.css';

interface State {
  fileList: EntityFile[],
  error: boolean
}

const { Dragger } = Upload;

interface Props extends FormComponentProps {
  value?: string
  Component?: any
  extraProps: {}
  entityId?: number
  contentType: number
  userId: number
  onUploadComplete: (data: Record<string, any>) => {}
}

// 10mb
const MAX_FILE_SIZE = 10240;
const FILE_SIZE_EXTENSION = 'MB';

class FileUploadField extends React.Component<Props, State> {
  static defaultProps = {
    extraProps: {},
  };

  state = {
    fileList: [],
    error: false,
  };

  normalizeFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  handleCustomRequest = ({ file, onSuccess }: any) => {
    const { userId, entityId, contentType } = this.props;
    const newFile = new EntityFile({
      file,
      name: file.name,
      /* eslint-disable @typescript-eslint/camelcase */
      content_type: contentType,
      uploaded_by: userId,
      object_id: entityId,
      /* eslint-enable */
    });
    saveImage(newFile).then(response => {
      onSuccess('Ok');
      this.props.onUploadComplete(response.data);
    });
  };

  render() {
    const uploadProps = {
      onRemove: (file: any) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();

          newFileList.splice(index, 1);

          return { fileList: newFileList };
        });
      },

      beforeUpload: (file: any) => {
        // 10 megabytes
        if (file.size > MAX_FILE_SIZE) {
          this.setState({ error: true });
        }
        this.setState((state) => {
          const newFileList = [...state.fileList, file];

          return { fileList: newFileList };
        });
        return true;
      },
    };

    const { form } = this.props;
    const { error } = this.state;
    const help = error ? `Files larger than ${MAX_FILE_SIZE} ${FILE_SIZE_EXTENSION} Mb will be skipped` : '';

    return (
      <Form layout="vertical">
        <Form.Item help={help} validateStatus={error ? 'warning' : ''} hasFeedback>
          {form.getFieldDecorator('file', {
            valuePropName: 'fileList',
            initialValue: [],
            getValueFromEvent: this.normalizeFile,
          })(
            <Dragger
              {...uploadProps}
              customRequest={this.handleCustomRequest}
              multiple
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text"><IntlMessages id="fileupload.title"/></p>
            </Dragger>,
          )}
        </Form.Item>
      </Form>
    );
  }
}

export const FileUploadForm = Form.create<Props>({})(FileUploadField);
