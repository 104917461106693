import React from 'react';

/**
 *
 * Compares 2 semver and returns
 * 1 if v2 is greater
 * 0 if they are equal
 * -1 if v2 is lower than v1
 *
 * @returns {boolean|number}
 */
function compareVersion(v1, v2) {
  if (process.env.NODE_ENV === 'development') return 0;

  if (typeof v1 !== 'string') return false;
  if (typeof v2 !== 'string') return false;
  v1 = v1.split('.');
  v2 = v2.split('.');
  const k = Math.min(v1.length, v2.length);
  for (let i = 0; i < k; ++i) {
    v1[i] = parseInt(v1[i], 10);
    v2[i] = parseInt(v2[i], 10);
    if (v1[i] > v2[i]) return 1;
    if (v1[i] < v2[i]) return -1;
  }

  return v1.length === v2.length ? 0 : (v1.length < v2.length ? -1 : 1);
}

/**
 * now we should use SemVer to generate a meta.json file on each build. meta.json won't be cached by the browser.
 * the generation should be automatic now with a prebuild hook
 * we use the following commands to update our package.json version
 * npm version patch — for releases with only bug fixes
 * npm version minor — for releases with new features w/ or w/o bug fixes
 * npm version major — for major releases or breaking features
 */
export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = compareVersion(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}