/* eslint-disable react/no-unused-state */
import React from 'react';
import { siteConfig } from '../settings';

interface HelmetContextState {
  title: string,
  shouldUseContextTitle: boolean,
}

export interface IHelmetContext {
  state: HelmetContextState,
  setTitle: (arg0: string) => null
}

export const HelmetContext = React.createContext({});

export class HelmetContextProvider extends React.Component {
  state: HelmetContextState = {
    title: siteConfig.siteName,
    shouldUseContextTitle: false,
  };

  setTitle = (title: string) => this.setState({ title });

  render() {
    return (
      <HelmetContext.Provider value={{
        state: this.state,
        setTitle: this.setTitle,
      }}>
        {this.props.children}
      </HelmetContext.Provider>
    );
  }
}