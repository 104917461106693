import { makeRequest, METHODS } from 'core/api/httpClient';
import { ContentType } from 'core/entities';

export async function fetchContentTypes() {
  const data: ContentType[] = await makeRequest({ url: 'core/content_types', method: METHODS.GET });

  const duplicated = data.find((entry, _, array) => {
    return !!array.find(element => element.model === entry.model && element.id !== entry.id);
  });

  if (duplicated) {
    throw new Error(`The model ${duplicated.model} content type exists in more than one app.`);
  }

  return data || [];
}
