import { BaseModel } from '.';
import { ColumnConfig } from 'components';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { eDataType } from './DataType';


export class DurationGoal extends BaseModel {
    static NAME = 'DurationGoal';
    static CONTENT_TYPE_NAME = 'durationgoal';
    static END_POINT = 'activity/duration_goals';

    id: Id;
    user: Id;
    operator: string;
    target: number;
    target_in_hours: number;
    content_type: number;
    object_id: number;
    content_object: number;

    static columnConfig: ColumnConfig[] = [
        { key: 'id', label: 'Id', width: 30 },
    ];

    static FORM_FIELDS: FieldDefinition<DurationGoal>[] = [
        {
            key: 'target_in_hours',
            label: 'Productive Hours per day',
            dataType: eDataType.NUMBER,
            inputType: eInputType.NUMBER
        },
    ];
}
