export class ImageEntity {
  src: string = '';
  thumbnail: string = '';
  name: string = '';
  id?: number = undefined;

  constructor(configObject: ImageConfig) {
    this.src = configObject.src;
    this.thumbnail = configObject.thumbnail || this.src;
    this.name = configObject.name;
    this.id = configObject.id;
  }
}

interface ImageConfig {
  src: string,
  thumbnail: string,
  name: string,
  id: number
}
