/* eslint-disable @typescript-eslint/camelcase */

import { IsEmail, IsNotEmpty, MaxLength } from 'class-validator';
import { ColumnConfig, eInputType, FieldDefinition } from 'components';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';
import { Workspace } from './Workspace';

export class User extends BaseEntity {
  static END_POINT = 'auths/users';
  static NAME = 'user';
  static CONTENT_TYPE_NAME = 'user';

  @IsEmail()
  email!: string;

  @MaxLength(100)
  first_name!: string;

  @MaxLength(100)
  last_name!: string;

  role?: number;

  is_active: boolean = false;

  workspaces!: Workspace[];

  readonly company?: number;
  readonly company_name?: string;

  getFullName() {
    if (!this.first_name && !this.last_name) return this.email;
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  getFirstInitial(): string {
    const name = this.first_name || this.last_name || this.email;
    return name[0].toUpperCase();
  }

  getInitials(): string {
    if (!this.first_name && !this.last_name) return 'Me';
    const fullName = `${this.first_name ||''} ${this.last_name || ''}`;
    return fullName.split(' ').filter(v => v).map(v => v[0].toUpperCase()).join('');
  }

  constructor(configuration: Partial<User>) {
    super();
    copyExistingProps(this, configuration);
  }

  static columnConfig: ColumnConfig[] = [
    { key: 'email', isClickable: true },
    { key: 'first_name' },
  ];

  static FORM_FIELDS: FieldDefinition<User>[] = [
    { key: 'email', inputType: eInputType.TEXT, dataType: eDataType.STRING, isRequired: true },
    { key: 'first_name', inputType: eInputType.TEXT, dataType: eDataType.STRING },
    { key: 'last_name', inputType: eInputType.TEXT, dataType: eDataType.STRING },
    {
      key: 'role',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'core/roles' },
    },
    { key: 'is_active', inputType: eInputType.CHECK_BOX, dataType: eDataType.STRING },
  ];

}
