import React, { Component } from 'react';
import { connect } from 'react-redux';
import appActions from '../../redux/app/actions';
import { Link } from 'react-router-dom';

const { fetchUnreadNotifications } = appActions;

class TopbarNotification extends Component {
  componentDidMount() {
    // 5 minutes
    const delay = 300000;
    this.props.fetchUnreadNotifications();

    const handleTimeout = () => {
      this.props.fetchUnreadNotifications();

      setTimeout(handleTimeout, delay);
    };

    this.timer = setTimeout(handleTimeout, delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  render() {
    const { notificationCount } = this.props;
    return null;
    // Disable notifications for now.
    // return (
    //   <Link to="/notifications">
    //     <div className="isoIconWrapper">
    //       <i
    //         className="ion-android-notifications"
    //         style={{ color: '#323332' }}
    //       />
    //       {notificationCount > 0 && <span>{notificationCount}</span>}
    //     </div>
    //   </Link>
    // );
  }
}

export default connect(state => ({
  notificationCount: state.App.notificationCount,
}),
  { fetchUnreadNotifications },
)(TopbarNotification);
