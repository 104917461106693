/* eslint-disable @typescript-eslint/camelcase */
import { Length, MaxLength, IsNotEmpty } from 'class-validator';
import { FieldDefinition } from 'components';
import { eInputType } from 'components/DynamicForm';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Company extends BaseEntity {
  static NAME = 'company';
  static CONTENT_TYPE_NAME = 'company';
  static END_POINT = 'core/companies';

  @MaxLength(30)
  @IsNotEmpty()
  name: string = '';

  @MaxLength(65535)
  address: string = '';

  @MaxLength(30)
  code: string = '';

  @Length(0, 20)
  city: string = '';

  is_active: boolean = true;

  @Length(0, 15)
  phone_number: string = '';

  @Length(0, 30)
  country: string = '';

  @Length(0, 50)
  website: string = '';

  logo: string = '';

  maintenance_team_size: string = '';

  static FORM_FIELDS: FieldDefinition<Company>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    {
      key: 'address',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT_AREA,
    },
    { key: 'code', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    { key: 'city', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    {
      key: 'phone_number',
      label: 'Phone',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT,
    },
    { key: 'country', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    { key: 'website', dataType: eDataType.STRING, inputType: eInputType.TEXT },
  ];

  constructor(obj: Partial<Company>) {
    super();
    copyExistingProps(this, obj);
  }
}
