import { BaseModel } from '.';

export class ActivityCategory extends BaseModel {
    static NAME = 'ActivityCategory';
    static CONTENT_TYPE_NAME = 'activitycategory';
    static END_POINT = 'activity/activity_categories';

    readonly id: number;
    readonly name: string;
    readonly parent: number;
    readonly productivity: number;
}
