interface MenuOption {
  default?: boolean;
  key: string;
  label: string;
  leftIcon: string;
}

const menuOptions: MenuOption[] = [
  {
    default: true,
    key: 'dashboard',
    label: 'sidebar.dashboard',
    leftIcon: 'pie-chart-outline',
  },
  {
    key: 'activities',
    label: 'sidebar.activities',
    leftIcon: 'albums-outline',
  },
  // {
  //   key: 'categories',
  //   label: 'sidebar.categories',
  //   leftIcon: 'list-outline',
  // },
  // {
  //   key: 'garden',
  //   label: 'sidebar.garden',
  //   leftIcon: 'leaf-outline',
  // },
  {
    key: 'projects',
    label: 'sidebar.projects',
    leftIcon: 'folder-open-outline',
  },
  {
    key: 'tags',
    label: 'sidebar.tags',
    leftIcon: 'pricetag-outline',
  },
  // {
  //   key: 'users',
  //   label: 'sidebar.users',
  //   leftIcon: 'people-outline',
  // },
  // {
  //   key: '.users',
  //   label: 'sidebar.users',
  //   leftIcon: 'ion-document',
  //   children: [
  //     {
  //       key: 'users/invites',
  //       label: 'sidebar.workspaceInvitations',
  //     },
  //     {
  //       key: 'users/teams',
  //       label: 'sidebar.teams',
  //     },
  //     {
  //       key: 'users/roles',
  //       label: 'sidebar.roles',
  //     },
  //   ],
  // },
  // {
  //   key: 'settings',
  //   label: 'sidebar.settings',
  //   leftIcon: 'cog-outline',
  // },
  // {
  //   key: 'files',
  //   label: 'sidebar.files',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'calendar',
  //   label: 'sidebar.calendar',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'facilities',
  //   label: 'sidebar.facilities',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'units',
  //   label: 'sidebar.units',
  //   leftIcon: 'ion-document',
  // },
  //   children: [
  //     {
  //       key: 'units/blank-page',
  //       label: 'sidebar.blankPage',
  //     },
  //   ],
  // },
  // {
  //   key: 'importer',
  //   label: 'sidebar.importer',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'facility-categories',
  //   label: 'sidebar.facilityCategories',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'work-requests',
  //   label: 'sidebar.workRequest',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'work-orders',
  //   label: 'sidebar.workOrder',
  //   leftIcon: 'ion-document',
  // },
  // {
  //   key: 'work-order-categories',
  //   label: 'sidebar.workOrderCategory',
  //   leftIcon: 'ion-document',
  // },
];

export default menuOptions;
