import React from 'react';
import TextField from '@atlaskit/textfield';

export function NumberInputField(props: any) {
  const { value = '', onChange, ...rest } = props;
  const [text, setText] = React.useState('');

  const handleChange = (e: any) => {
    const val = e.target.value;
    setText(val);
    onChange(val);
  };

  React.useEffect(() => setText(value), [value]);
  return (
    <TextField
      {...rest}
      value={text}
      onChange={handleChange}
      type="Number"
    />
  );
}
