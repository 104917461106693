import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .refresh-div {
    display: flex;
    background-color: orange;
    border-style: solid;
    border-width: 0.1px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 2000;
    
    p {
      margin: 0;
    }
    
    button {
    padding: 0
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export default DashAppHolder;
