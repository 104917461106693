import { toInstance } from 'helpers/utility';
import { BaseModel } from '../entities';
import { HTTP_METHODS, makeRequest, QueryParams, RequestConfig } from './httpClient';


class EntityClient {

    async createEntities<T extends BaseModel>(
        entityClass: ModelConstructor<T>,
        data?: RequestConfig['data']
    ): Promise<T[]> {

        const method = HTTP_METHODS.POST;
        const response = await makeRequest<T[]>({ url: this.endpointOf<T>(entityClass), method, data });
        return (response || []).map(x => toInstance<T>(x, entityClass));
    }

    async updateEntities<T extends BaseModel>(
        entityClass: ModelConstructor<T>,
        data?: RequestConfig['data']
    ): Promise<T[]> {

        const method = HTTP_METHODS.PATCH;
        const response = await makeRequest<T[]>({ url: this.endpointOf<T>(entityClass), method, data });
        return (response || []).map(x => toInstance<T>(x, entityClass));
    }

    async getEntities<T>(
        entityClass: ModelConstructor<T>,
        queryParams?: QueryParams
    ): Promise<T[]> {

        const requestConfig: RequestConfig = {
            url: this.endpointOf<T>(entityClass),
            method: HTTP_METHODS.GET,
            queryParams
        };
        const response = await makeRequest<T[]>(requestConfig);
        return (response && response.results || []).map(x => toInstance<T>(x, entityClass));
    }

    /**
     * Makes a raw GET request to the entity endpoint, with an optional resource.
     */
    async getToEntityEndpoint<T>(
        entityClass: ModelConstructor<any>,
        resource = '',
        queryParams?: QueryParams
    ) {

        if (resource.startsWith('/') || resource.endsWith('/')) {
            throw Error(`resource ${resource} should not start or end with "/"`);
        }
        const method = HTTP_METHODS.GET;
        const resourcePath = resource && `/${resource}`;
        const url = `${this.endpointOf(entityClass as any)}${resourcePath}`;
        return makeRequest<T>({ url, method, queryParams });
    }

    endpointOf<T>(entityClass: ModelConstructor<T>) {
        // TODO: Fix this file
        // @ts-ignore
        return entityClass.END_POINT;
        // if (!entityClass._meta || !entityClass._meta.endpoint) {
        //     throw Error(`Class "${entityClass.constructor.name}" is missing _meta.endpoint`);
        // } else if (entityClass._meta.endpoint.endsWith('/')) {
        //     throw Error(`Class "${entityClass.constructor.name}" _meta.endpoint should not end with "/"`);
        // }
        // return entityClass._meta.endpoint;
    }

}

const entityClient = new EntityClient();

export default entityClient;
