import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd/lib/index';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import { authActions } from '../redux/auth/actions';
import appActions from '../redux/app/actions';
import Sidebar from '../components/Sidebar/Sidebar';
import Topbar from '../pages/Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig, themeConfig } from '../settings';
import themes from '../settings/themes';
import AppHolder from './commonStyle';
import { DynamicDrawer, DynamicModal } from '../components';
import HistoryForm from '../components/HistoryForm/HistoryForm';
import { AppHelmet } from '../components/Helmet/Helmet';
import { HelmetContextProvider } from '../context/HelmetContext';
import { ID_TOKEN } from '../helpers/store';


const { Content, Footer } = Layout;
const { logout } = authActions;
const { toggleAll } = appActions;

export class App extends Component {
  componentDidMount() {
    window.addEventListener('storage', this.onLoggedStatusChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.onLoggedStatusChange);
  }

  /**
   * this function dispatch a logout function for redux each whenever we get a null value on the key id_token
   */
  onLoggedStatusChange = (e) => {
    if (e.key === ID_TOKEN && e.newValue === null) {
      this.props.logout();
    }
  };

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;

    return (
      <React.Fragment>
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <HelmetContextProvider>
            <AppHelmet />
            <DynamicModal />
            <DynamicDrawer />
            <HistoryForm {...this.props} />
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight,
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '40px 0 0',
                        flexShrink: '0',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed',
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </HelmetContextProvider>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height,
    isLoading: state.App.isLoading,
  }),
  { logout, toggleAll },
)(App);
