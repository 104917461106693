import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from 'components';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Project extends BaseEntity {
  static NAME = 'Project';
  static CONTENT_TYPE_NAME = 'project';
  static END_POINT = 'focus/projects';

  user?: Id;

  @IsNotEmpty()
  @MaxLength(50)
  name = '';

  client?: Id;
  color?: string;
  user_project_hourly_rates: UserProjectHourlyRate[];

  static FORM_FIELDS: FieldDefinition<Project>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    // TODO: ADD FIELD ON BACKEND
    // { key: 'color', dataType: eDataType.STRING, inputType: eInputType.COLOR_CHOOSER, isRequired: false },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
  ];

}

export class UserProjectHourlyRate extends BaseEntity {
  user: Id;
  project: Id;
  hourly_rate: number;
}
