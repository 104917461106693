import { IsNotEmpty, MaxLength } from 'class-validator';
import { ColumnConfig, FieldDefinition } from 'components';
import { eInputType } from '../../components/DynamicForm';
import { PriorityType } from 'helpers/entities';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';


export class WorkOrder extends BaseEntity {
  static NAME = 'work order';
  static CONTENT_TYPE_NAME = 'workorder';
  static END_POINT = 'work/work_orders';

  @IsNotEmpty()
  @MaxLength(200)
  name = '';

  due_date: string = '';

  priority?: PriorityType = undefined;

  category?: number = undefined;

  asset?: number = undefined;

  assignee?: number = undefined;

  secondary_assignees ?: number = undefined;

  team?: number = undefined;

  static FORM_FIELDS: FieldDefinition<WorkOrder>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    {
      key: 'due_date',
      dataType: eDataType.DATE,
      inputType: eInputType.DATE,
    },
    {
      key: 'priority',
      inputType: eInputType.GROUP_RADIO_BUTTON,
      dataType: eDataType.STRING,
      fieldOptions: {
        options: [
          { value: 'low', color: ' #ebc034' },
          { value: 'medium', color: '#eb9634' },
          { value: 'high', color: '#eb5c34' },
        ],
      },
    },
    {
      key: 'asset',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'assets/assets' },
    },
    {
      key: 'category',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'work/work_order_categories' },
    },
    {
      key: 'assignee',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'auths/users' },
    },
    {
      key: 'secondary_assignees',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { isMulti: true, endpoint: 'auths/users' },
    },
    {
      key: 'team',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'core/teams' },
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'team', accessor: 'team_name' },
    { key: 'category', accessor: 'category_name' },
  ];

  constructor(obj: Partial<WorkOrder>) {
    super();

    copyExistingProps(this, obj);
  }
}
