import styled from "styled-components";

const MainStyle = styled.div`
  height: 100%;
  padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;

  .overlay {
    background: rgba(255, 255, 255, .4);
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px 0;
  }
`;

export default MainStyle;
