import { OnlyButtonProps } from '@atlaskit/button/types';
import { eDataType } from 'core/entities';
import Button from '@atlaskit/button';
import { copyExistingProps } from '../../helpers/utility';
import {
  AsyncSelectField,
  AsyncValidationTextField,
  CheckBoxField,
  DatePickerField,
  RadioButtonGroup,
  NumberInputField,
  SelectField,
  TextAreaField,
  TextInputField,
  ColorChooserField
} from './Components';
import { FileUploadField } from './Components/FileUploadField/FileUploadField';
import { MapField } from './Components/MapField/MapField';
import { HiddenInput, Image } from './DynamicForm.style';

export enum eInputType {
  NUMBER,
  TEXT,
  COLOR_CHOOSER,
  FILE,
  ASYNC_SELECT,
  TEXT_AREA,
  DATE,
  HIDDEN,
  BUTTON,
  IMAGE_VIEW,
  CHECK_BOX,
  MAP_VIEW,
  GROUP_RADIO_BUTTON,
  SELECT,
  ASYNC_VALIDATION_TEXT_FIELD
}

export class FieldDefinition<T> {
  dataType?: eDataType = undefined;
  inputType!: eInputType;
  key!: keyof T & string;
  isRequired?: boolean = false;
  isDisabled?: boolean = false;
  value?: string = '';
  label?: string | false;
  accessor?: string = '';
  fieldOptions?: AsyncSelectConfig | SelectConfig | TextConfig | ButtonConfig | GroupRadioOption | CheckboxConfig = undefined;

  public constructor(obj: Partial<FieldDefinition<T>>) {
    copyExistingProps(this, obj);
  }
}

export const getComponentType = (inputType?: eInputType): [Function, string] => {
  let Component: Function = () => {
  };
  let className = '';

  switch (inputType) {
    case eInputType.TEXT:
      className = 'text-input-field';
      Component = TextInputField;
      break;
    case eInputType.NUMBER:
      className = 'number-input-field';
      Component = NumberInputField;
      break;
    case eInputType.COLOR_CHOOSER:
      Component = ColorChooserField;
      break;
    case eInputType.ASYNC_SELECT:
      Component = AsyncSelectField;
      break;
    case eInputType.TEXT_AREA:
      Component = TextAreaField;
      break;
    case eInputType.DATE:
      Component = DatePickerField;
      break;
    case eInputType.BUTTON:
      className = 'button-field';
      Component = Button;
      break;
    case eInputType.IMAGE_VIEW:
      Component = Image;
      break;
    case eInputType.FILE:
      Component = FileUploadField;
      break;
    case eInputType.HIDDEN:
      Component = HiddenInput;
      break;
    case eInputType.CHECK_BOX:
      Component = CheckBoxField;
      break;
    case eInputType.MAP_VIEW:
      Component = MapField;
      break;
    case eInputType.GROUP_RADIO_BUTTON:
      Component = RadioButtonGroup;
      break;
    case eInputType.SELECT:
      Component = SelectField;
      break;
    case eInputType.ASYNC_VALIDATION_TEXT_FIELD:
      Component = AsyncValidationTextField;
      break;
    default:
      throw new Error(`case not defined ${inputType}`);
  }

  return [Component, className];
};

interface OptionString {
  (option: { [index: string]: string }): string
}

export interface TextConfig {
  type?: 'password'
  elemBeforeInput?: JSX.Element
  elemAfterInput?: JSX.Element
}

export class SelectConfig {
  onChange?: Function;
  isMulti?: boolean = false;
  selectOption?: { label: string, value: string };
  placeholder?: string;
  selectedValue?: object | object[];
  options: any;
  value?: number | string;
  optionLabel?: OptionString;
  optionValue?: OptionString;
  className?: string;
  id?: string;
}

export class AsyncSelectConfig {
  endpoint?: string;
  isMulti?: boolean = false;
  selectOption?: { name: string, value: string };
  placeholder?: string;
  selectedValue?: object | object[];
  value?: any;
  optionLabel?: OptionString;
  optionValue?: OptionString;
}

export interface ButtonConfig extends OnlyButtonProps {
}

export type GroupRadioOption = { value: string | number, label?: string, tooltip?: string, color?: string, textColor?: string, borderColor?: string }

export interface GroupRadioConfig {
  options: GroupRadioOption[]
}

export interface CheckboxConfig {
  label?: string
}