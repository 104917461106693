import Axios from 'axios';

const APP_ID = 'XhMsunRCvOxGyPBMRmX4';
const APP_CODE = 'M0QsMaHhKh4So9hpLljm4A';

export async function getLatLong(searchText?: string): Promise<{ Latitude: number, Longitude: number } | undefined> {
  if (!searchText) return undefined;

  try {
    const response = await Axios.get(`https://geocoder.api.here.com/6.2/geocode.json?app_id=${APP_ID}&app_code=${APP_CODE}&searchtext=${searchText}`);

    return response.data.Response.View[0].Result[0].Location.DisplayPosition;
  } catch {
    return undefined;
  }
}
