import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import AntDatePicker from './styles/datePicker.style';

// Override moment with dayjs
const OverriddenDatePicker = generatePicker < Dayjs > (dayjsGenerateConfig);

const DatePicker = AntDatePicker(OverriddenDatePicker);
const DateRangePicker = AntDatePicker(OverriddenDatePicker.RangePicker);

export default DatePicker;
export { DateRangePicker };
