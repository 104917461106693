import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from 'components';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Role extends BaseEntity {
  static NAME = 'role';
  static CONTENT_TYPE_NAME = 'role';
  static END_POINT = 'core/roles';

  @IsNotEmpty()
  @MaxLength(50)
  name = '';

  @MaxLength(2014)
  description = '';

  users?: number[] = [];

  static FORM_FIELDS: FieldDefinition<Role>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    { key: 'description', dataType: eDataType.STRING, inputType: eInputType.TEXT_AREA },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'description' },
  ];

  constructor(o: Partial<Role>) {
    super();

    copyExistingProps(this, o);
  }
}
