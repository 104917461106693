import styled from "styled-components";
import { palette } from "styled-theme";
import { transition } from "../../settings/style-util";
import WithDirection from "../../settings/withDirection";

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    // background: rgba(0,0,0,.87);
    background: rgba(32,31,31,1);
    width: 280px;
    flex: 0 0 280px;

    @media only screen and (max-width: 767px) {
      width: 230px !important;
      flex: 0 0 230px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      margin: 0;
      text-align: center;
      overflow: hidden;
      position: relative;
      left: -1px;

      h3 {
        a {
          font-size: 21px;
          line-height: 70px;
          letter-spacing: 3px;
          color: ${palette("grayscale", 6)};
          display: inline-block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 55px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        height: 36px !important;
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        padding-left: 33px !important;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;
      }

      .icon {
        font-size: 18px;
        margin-right: 10px;
        color: inherit;
        ${transition()};
      }

      i {
        font-size: 19px;
        color: inherit;
        margin: ${props =>
    props["data-rtl"] === "rtl" ? "0 0 0 10px" : "0 10px 0 0"};
        width: 18px;
        ${transition()};
      }

      .nav-text {
        position: relative;
        top: 1px;
        font-size: 14px;
        color: inherit;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.4) !important;

        .nav-text {
          color: #fff;
        }

        > a:not(:hover) {
          icon, span {
            color: ${palette("primary", 1)};
          }
        }

      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #ffffff;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette("secondary", 5)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;

        > span {
          display: flex;
          align-items: center;
        }

        &:hover {
          &:after {
            color: #ffffff;
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
    props["data-rtl"] === "rtl" ? "0px !important" : "62px !important"};
          padding-right: ${props =>
    props["data-rtl"] === "rtl" ? "62px !important" : "0px !important"};
          font-weight: 400;
          color: inherit;
          ${transition()};

          &:hover {
            color: #ffffff;
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline >  {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 30px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
