import styled from 'styled-components';

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
`;

export const Image = styled.img`
  max-height: 200px;
  max-width: 200px;
  flex: 1;
  margin: 5px;
  object-fit: contain;
  cursor: pointer;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const ImageDiv = styled.div`
  position: relative;
  display: flex;

  :hover {
    img {
      opacity: 0.4;
    }
    
    .icon {
      display: flex;
      opacity: 1;
    }
  }
  
  .icon {
    display: none;
    z-index: 1;
    position: absolute;
  }
`;
