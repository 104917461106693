import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { IRoute, routes } from 'App/AppRouter';
import Container from './Breadcrumbs.style';


export const Breadcrumbs: React.ComponentType = withRouter((props) => {
  const { location, changeCurrent } = props as any;
  const pathSnippets = location.pathname.split('/').filter((i: string) => i);

  const breadcrumbItems = pathSnippets.map((_: any, index: number) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const route = routes.find((r: IRoute) => {
      return `/${r.path.toLowerCase()}` === url.toLowerCase();
    });

    if (!route) return null;

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url} onClick={() => changeCurrent([route.path])}>{route.name}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <Container>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </Container>
  );
});
