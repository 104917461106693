import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;

 .number-input-field {
  min-height: 30px;
 }
 
 .text-input-field {
  min-height: 30px;
 }
 
 .button-field {
  padding-left: 0;
 }

 button {
  transition: none;
 }
`;

export const Image = styled.img`
  max-height: 200px;
  max-width: 200px;
  flex: 1;
  margin: 5px;
  object-fit: contain;
  cursor: pointer;
`;

export const HiddenInput = styled.div`
`;

export interface FooterStyle {
  position?: 'relative' | 'absolute'
  stickyFooter?: boolean
}

export const FormFooter = styled.div<FooterStyle>`
  position: ${props => {
    if (!props.stickyFooter) {
      return 'relative';
    }
    return props.position || 'absolute';
  }};
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: ${props => !props.stickyFooter ? '0px' : '1px solid #e9e9e9'};  
  padding: ${props => !props.stickyFooter ? '10px 0' : '15px 15px 20px'};
  background: #fff;
  text-align: right; 
  
  .delete-button{
    margin-right: 6px;
  }

  .cancel-button{
    margin-right: 6px;
  }
`;
