import styled from 'styled-components';

export const SingleContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

export const DisabledTab = styled.p`
    align-self: center;
    color: darkgray;
`;
