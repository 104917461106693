import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';

interface Props {
  onChange: any
}

interface State {
  file?: File
  uploading: boolean
}

export class FileUploadField extends React.Component<Props, State> {
  state = {
    uploading: false,
  };

  render() {
    const extraProps: any = {
      onRemove: (_: any) => {
        this.props.onChange(null);
      },
      beforeUpload: (file: File) => {
        this.setState({ file });
        this.props.onChange(file);
        return false;
      },
    };

    return (
      <Upload {...extraProps} multiple={false}>
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
    );
  }
}