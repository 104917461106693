import React, { useState } from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { DateTime } from 'luxon';

export function DatePickerField(props: any) {
  const { value = '', onChange, ...rest } = props;
  const [date, setDate] = useState('');

  const handleChange = (val: any) => {
    const date = DateTime.fromISO(val).toISO();
    setDate(date);
    onChange(date);
  };

  React.useEffect(() => setDate(value), [value]);

  return (
    <DatePicker
      {...rest}
      value={date}
      onChange={handleChange}
    />
  );
}
