import styled from 'styled-components';

const SwitchableDatePickerFieldWrapper = styled.div`

  .ant-btn,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {   
      box-shadow: ${props => props.theme.boxShadow.small};
  }
`;

export { SwitchableDatePickerFieldWrapper };