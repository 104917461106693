import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { actions } from 'redux/popOutContent/actions';

const { hideContent } = actions;

class DynamicModalConnected extends PureComponent<any> {

  handleCancel = () => {
    this.props.onCancel();
    this.props.hideContent();
  };

  render() {
    const { Content, title, isVisible, target } = this.props;

    return (
      <Modal
        title={title}
        visible={target === 'modal' && isVisible}
        footer={null}
        bodyStyle={{ overflow: 'auto', height: '60vh' }}
        onCancel={this.handleCancel}
        centered
      >
        <Content />
      </Modal>
    );
  }
}

export const DynamicModal = connect(
  (state: any) => ({ ...state.popOutContent }),
  { hideContent },
)(DynamicModalConnected);
