import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from 'components';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Team extends BaseEntity {
  static NAME = 'team';
  static CONTENT_TYPE_NAME = 'team';
  static END_POINT = 'core/teams';

  @IsNotEmpty()
  @MaxLength(50)
  name = '';

  @MaxLength(2014)
  description = '';

  users?: number[] = [];

  static FORM_FIELDS: FieldDefinition<Team>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    { key: 'description', dataType: eDataType.STRING, inputType: eInputType.TEXT_AREA },
    {
      key: 'users',
      dataType: eDataType.NUMBER,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { isMulti: true, endpoint: 'auths/users' },
      isRequired: true,
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'description' },
  ];

  constructor(o: Partial<Team>) {
    super();

    copyExistingProps(this, o);
  }
}
