import { PriorityType } from '../../helpers/entities';
import { copyExistingProps } from '../../helpers/utility';
import { BaseEntity } from './BaseEntity';

export class Notification extends BaseEntity {
  static END_POINT = 'notifications/notifications';
  static UNREAD_NOTIFICATIONS = 'notifications/notifications/unread';
  static NAME = 'notification';
  static CONTENT_TYPE_NAME = Notification.NAME;

  readonly created_date: string = '';
  description: string = '';
  priority?: PriorityType = undefined;
  to?: number = undefined;
  is_read: boolean = false;

  constructor(o: Partial<Notification>) {
    super();

    copyExistingProps(this, o);
  }
}
