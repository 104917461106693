
export enum eDataType {
    STRING,
    DECIMAL,
    NUMBER,
    DATE,
    IMAGE,
    SELECT_ONE,
    OBJECT,
    BOOLEAN,
}
