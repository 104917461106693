import { makeRequest, METHODS, QueryParams, RequestConfig } from 'core/api/httpClient';
import { toInstance } from 'helpers/utility';

export async function saveEntity<T>(endpoint: string, data: { id?: number | undefined }, classConstructor?: Constructor<T>): Promise<T> {
  const method = data.id ? METHODS.PATCH : METHODS.POST;
  const requestConfig: RequestConfig = { url: endpoint, method, data, id: data.id };

  const updatedEntity = await makeRequest(requestConfig);
  return classConstructor ? toInstance(updatedEntity, classConstructor) : updatedEntity;
}

export async function getEntities<T>(endpoint: string, queryParams?: QueryParams, classConstructor?: Constructor<T>): Promise<T[]> {
  const requestConfig: RequestConfig = { url: endpoint, method: METHODS.GET, queryParams };
  const entities = await makeRequest(requestConfig) as [];
  return classConstructor ? entities.map(x => toInstance(x, classConstructor)) : entities;
}

export async function getEntity<T>(endpoint: string, id?: number, classConstructor?: Constructor<T>): Promise<T> {
  const entity = await makeRequest({ url: endpoint, method: METHODS.GET, id });
  return classConstructor ? toInstance(entity, classConstructor) : entity;
}

export async function deleteEntity(endpoint: string, id: number): Promise<any> {
  return makeRequest({ url: endpoint, method: METHODS.DELETE, id });
}
