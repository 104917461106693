import { IsNotEmpty, MaxLength } from 'class-validator';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { ColumnConfig } from 'components';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class Tag extends BaseEntity {
  static NAME = 'Tag';
  static CONTENT_TYPE_NAME = 'tag';
  static END_POINT = 'focus/tags';

  user?: Id;

  @IsNotEmpty()
  @MaxLength(50)
  name = '';

  static FORM_FIELDS: FieldDefinition<Tag>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
  ];

}
