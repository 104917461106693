import { ErrorMessage, Field } from '@atlaskit/form';
import React from 'react';

interface Props {
  errors: { [key: string]: string[] };
  name: string,
  label?: string | React.ReactElement,
  defaultValue?: any,
  validate?: Function
}

export class AutoSubmitField extends React.PureComponent<Props> {
  render() {
    const {
      errors, name, label, defaultValue = '', validate = () => {
      },
    } = this.props;
    const { children } = this.props as any;
    return (
      <Field name={name} defaultValue={defaultValue} label={label || name}>
        {({ fieldProps }: any) => {
          const handleChange = (e: any) => {
            fieldProps.onChange(e);

            validate(e);
          };
          const error: any = errors[name];
          return <React.Fragment>
            {children && children({ ...fieldProps, onChange: handleChange })}
            {error && error.map((err: string) => <ErrorMessage key={err}>{err}</ErrorMessage>)}
          </React.Fragment>;
        }}
      </Field>
    );
  }
}
