import React from 'react';
import { Calendar as EventCalendar, momentLocalizer, CalendarProps } from 'react-big-calendar';
// TODO: Replace this with dayjs, now that moment is not used anymore
// import moment from 'moment';

//const localizer = momentLocalizer(moment);

export class Calendar extends React.PureComponent {
  render() {
    // @ts-ignore
    const calendarOptions: CalendarProps = {
      popup: true,
      selectable: 'ignoreEvents',
      className: 'isomorphicCalendar',
      // TODO: Replace with dayjs
      // localizer,
      views: ['month'],
      onSelecting: () => false,
    };
    return <EventCalendar
      {...calendarOptions}
      {...this.props}
      onSelecting={() => false}
    />;
  }
}
