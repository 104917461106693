import React from 'react';
import { ColumnConfig } from 'components';
import { eInputType, FieldDefinition } from 'components/DynamicForm';
import { eDataType } from './DataType';
import { BaseModel } from '.';

interface ActiveApp {
  name: string;
  processId: number;
  path: string;
}

interface ActiveWindow {
  title: string;
  id: number;
  bounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  owner: ActiveApp;
  memoryUsage: number;
  url?: string;
}

export interface Duration {
  readonly as_clock: string; // 01:37
  readonly total_in_hours: number; // 1 (rounded down)
  readonly total_in_minutes: number; // 97 (rounded down)
  readonly total_in_seconds: number; // 5820 (exact)
}

export class UserEventActivity {
  static END_POINT = 'activity/user_events/activities';

  id: number;
  display_name: string;
  activity_category: number;
  icon: string;
  productivity?: number;
  total_occurrences: number;
  total_duration: string;
  total_duration_in_seconds: number;
  app_name: string;
  website_domain: string;

  static columnConfig: ColumnConfig[] = [];

  static FORM_FIELDS: FieldDefinition<UserEvent>[] = [
    {
      key: 'title',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT_AREA,
    },
    { key: 'data', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    {
      key: 'from_time',
      dataType: eDataType.STRING,
      inputType: eInputType.DATE,
    },
    { key: 'to_time', dataType: eDataType.STRING, inputType: eInputType.DATE },
  ];
}

type Seconds = number;
type ProductiveSecs = Seconds;
type NeutralSecs = Seconds;
type NonProductiveSecs = Seconds;
export class DurationFormat<T> {
  constructor(public value: T, public value_humanized: string) {}
}

export class UserEventActivitySummary {
  static END_POINT = 'activity/user_events/activity_summary';

  readonly duration_goal: {
    id: Id;
    target: Seconds;
    target_humanized: string;
    percentage_completed: number;
  };
  readonly total_duration_by_productivity: {
    productive: ProductiveSecs;
    neutral: NeutralSecs;
    non_productive: NonProductiveSecs;
  };
  readonly total_productivity_by_hour: {
    [hour: string]: [
      // '0' is midnight
      DurationFormat<ProductiveSecs>,
      DurationFormat<NeutralSecs>,
      DurationFormat<NonProductiveSecs>
    ];
  };
  readonly total_productivity_by_day_of_week: {
    [day: string]: [
      // '0' is Sunday
      DurationFormat<ProductiveSecs>,
      DurationFormat<NeutralSecs>,
      DurationFormat<NonProductiveSecs>
    ];
  };
  readonly total_productivity_by_month: {
    [month: string]: [
      // '0' is January
      DurationFormat<ProductiveSecs>,
      DurationFormat<NeutralSecs>,
      DurationFormat<NonProductiveSecs>
    ];
  };
}

export class UserEvent extends BaseModel {
  static NAME = 'UserEvent';
  static CONTENT_TYPE_NAME = 'userevent';
  static END_POINT = 'activity/user_events';

  id!: Id;
  time_entry!: Id;
  from_time!: string;
  to_time!: string;
  title!: string;
  description!: string;
  data!: ActiveWindow;
  readonly app_icon?: string;
  readonly website_icon?: string;
  readonly user!: Id;

  static columnConfig: ColumnConfig[] = [
    { key: 'activity_id', label: 'Id', width: 30 },
    { key: 'total_duration', label: 'Total', width: 70 },
    {
      id: 'icon',
      key: 'icon',
      accessor: (x) => x.icon,
      label: 'Icon',
      isImage: true,
      width: 40,
    },
    {
      id: 'activity',
      key: 'activity',
      accessor: (x) => x.website_domain || x.app_name,
      label: 'Activity',
      width: 300,
    },
    {
      key: 'activity_category',
      selectFieldConfig: {
        options: [
          { label: 'Adelaide', value: 'adelaide' },
          { label: 'Brisbane', value: 'brisbane' },
          { label: 'Canberra', value: 'canberra' },
          { label: 'Darwin', value: 'darwin' },
          { label: 'Hobart', value: 'hobart' },
          { label: 'Melbourne', value: 'melbourne' },
          { label: 'Perth', value: 'perth' },
          { label: 'Sydney', value: 'sydney' },
        ],
      },
    },
  ];

  static FORM_FIELDS: FieldDefinition<UserEvent>[] = [
    {
      key: 'title',
      dataType: eDataType.STRING,
      inputType: eInputType.TEXT_AREA,
    },
    { key: 'data', dataType: eDataType.STRING, inputType: eInputType.TEXT },
    {
      key: 'from_time',
      dataType: eDataType.STRING,
      inputType: eInputType.DATE,
    },
    { key: 'to_time', dataType: eDataType.STRING, inputType: eInputType.DATE },
  ];
}
