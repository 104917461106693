import styled from 'styled-components';

const Table = styled.div`    
    .checkbox-select {
      max-width: 300px;
      margin-bottom: 5px;
    }
    
    .actions {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      
      .checkbox-select {
        flex: 1 0 auto;
      }
      
      .button {
        height: 40px;
      }
    }
    
    .searchBar {
      width: 300px;
      margin-bottom: 20px;

      button {
        box-shadow: none;
        height: 32px;
      }
    }
    
    .clickable {
      cursor: pointer;
      color: blue;
      
      :hover {
          text-decoration: underline;
          color: gray;
      }
    }

    // React table overrides
    .-no-side-borders {
      border-left: none;
      border-right: none;

      .rt-thead .rt-th:first-child {
        padding-left: 8px;
        text-align: left;
      }
    }

    .-highlight .rt-table .rt-tbody .rt-tr:hover {
      background: rgba(0,0,0,0.03);
    }

    .rt-table, .rt-tbody {
      overflow: visible;
    }
    
    .rt-thead .rt-th {
      font-size: 13px;
      font-weight: 600;
    }

    .rt-thead.-header {
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 13%);
    }

    .rt-td {
      padding: 5px 8px 1px 8px;
      font-size: 14px;

      // Fix blurry images when resized.
      img {
        image-rendering: -moz-crisp-edges;           /* Firefox */
        image-rendering:   -o-crisp-edges;           /* Opera */
        image-rendering: -webkit-optimize-contrast;  /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;    /* IE (non-standard property) */
      }

      // Override select field.
      .select-field { 
        > div {
          top: 70%;
        }
        div[aria-hidden] span {
          color: #ccc;
          &:hover {
            color: #999;
          }
        }
      }
    }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .menu-item-icon{
    z-index: 10;
  
    :hover {
      cursor: pointer;
    }
  }
`;

export default Table;
