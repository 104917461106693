
export class ModelMeta {
  endpoint = '';
  className = '';
  contentTypeName = '';

  constructor(props: ModelMeta) {
    Object.keys(this).forEach(k => {
      if (!props[k as keyof ModelMeta] ) {
        throw Error(`Missing property "${k}" or empty value on ModelMeta.`);
      }
    });
  }
}

export abstract class BaseModel {
	id?: Id;
	readonly created?: Date;
  readonly last_updated?: Date;
  static readonly _meta: ModelMeta;

  constructor() {
    if (!(this.constructor as any)._meta) {
      throw Error(`Missing _meta property.`);
    }
  }
}

// export interface BaseEntity {
// 	id?: Id;
// }
