import React from 'react';
import {
  Checkbox,
} from '@atlaskit/checkbox';

export function CheckBoxField(props: any) {
  const { value, onChange, ...rest } = props;
  const [checked, checkChanged] = React.useState(value);
  const handleChange = (e: any) => {
    const val = e.currentTarget.checked;
    checkChanged(val);
    onChange(val);
  };

  React.useEffect(() => checkChanged(value), [value]);
  return (
    <Checkbox
      onChange={handleChange}
      isChecked={checked}
      {...rest}
    />
  );
}
