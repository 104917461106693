import React from 'react';
import Select from '@atlaskit/select';
import { SelectConfig } from '../..';


interface State {
  selectedOption?: SelectOption;
}

interface SelectOption {
  id: number,
  [label: string]: any,
}

export class SelectField extends React.Component<SelectConfig, State> {
  state: State = {};

  componentDidMount() {
    this.setState({
      selectedOption: this.findOption(this.props.value, this.props.options)
    });
  }

  findOption = (id: number | string | undefined, options: SelectOption[]) => {
    return options.find((option: SelectOption) => option.id === id);
  };

  onChange = (option: SelectOption) => {
    const { id } = option;

    if (this.state.selectedOption) {
      if (id === this.state.selectedOption.id) {
        return;
      }
    }

    this.setState({ selectedOption: option });

    if (this.props.onChange) {
      // TODO: Emit the value, plus the whole object. ie. onChange(id, selectedObject)
      this.props.onChange(id);
    }
  };

  optionValue = (option: { id: number; }) => option.id;
  optionLabel = (option: { label: any; }) => option.label;

  render() {
    const { selectedOption } = this.state;

    const { optionLabel, optionValue, ...rest } = this.props;
    const getOptionLabel = optionLabel || this.optionLabel;
    const getOptionValue = optionValue || this.optionValue;

    return (
      <Select
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        {...rest}
        onChange={this.onChange}
        value={selectedOption}
      />
    );
  }
}
