export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

const actions = {
  COLLAPSE_CHANGE: 'COLLAPSE_CHANGE',
  COLLAPSE_OPEN_DRAWER: 'COLLAPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLOSE_ALL: 'CLOSE_ALL',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  FETCH_CONTENT_TYPES_SUCCESS: 'FETCH_CONTENT_TYPES_SUCCESS',
  GET_USER_PREFERENCES_SUCCESS: 'GET_USER_PREFERENCES_SUCCESS',
  ADD_USER_TABLE_PREFERENCE: 'ADD_USER_TABLE_PREFERENCE',
  EDIT_USER_TABLE_PREFERENCE: 'EDIT_USER_TABLE_PREFERENCE',
  DELETE_USER_TABLE_PREFERENCE: 'DELETE_USER_TABLE_PREFERENCE',
  FETCH_UNREAD_NOTIFICATIONS: 'FETCH_UNREAD_NOTIFICATIONS',
  FETCH_UNREAD_NOTIFICATIONS_SUCCESS: 'FETCH_UNREAD_NOTIFICATIONS_SUCCESS',
  fetchUnreadNotifications: () => ({
    type: actions.FETCH_UNREAD_NOTIFICATIONS,
  }),
  fetchUnreadNotificationsSuccess: (notificationCount) => ({
    type: actions.FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
    notificationCount,
  }),
  getUserPreferenceSuccess: (userPreference) => ({
    type: actions.GET_USER_PREFERENCES_SUCCESS,
    userPreference,
  }),
  addUserTablePreference: (tablePreference) => ({
    type: actions.ADD_USER_TABLE_PREFERENCE,
    tablePreference,
  }),
  editUserTablePreference: (tablePreference) => ({
    type: actions.EDIT_USER_TABLE_PREFERENCE,
    tablePreference,
  }),
  deleteUserTablePreference: (tablePreference) => ({
    type: actions.DELETE_USER_TABLE_PREFERENCE,
    tablePreference,
  }),
  fetchContentTypeSuccess: (contentTypes) => ({
    type: actions.FETCH_CONTENT_TYPES_SUCCESS,
    contentTypes,
  }),
  toggleLoading: (isLoading) => ({
    type: actions.TOGGLE_LOADING,
    isLoading,
  }),
  toggleCollapsed: () => ({
    type: actions.COLLAPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLAPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  closeAll: () => ({ type: actions.CLOSE_ALL }),
};
export default actions;
