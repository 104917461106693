import React from 'react';
import { Transition, animated } from 'react-spring/renderprops';
import { Button } from 'antd';
import { TypeFormHolder } from './TypeForm.style';

interface Props {
  getValues: Function
  handleSubmit: Function
  validateForm: Function
  entityId?: number
  errors: Record<string, string[]>
}

interface State {
  current: number
  data: Record<string, any>
}

export class TypeForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const entityId = props.entityId ? { id: props.entityId } : {};
    this.state = {
      current: 0,
      data: { ...entityId },
    };
  }

  handleOnClick = async () => {
    const { getValues, validateForm } = this.props;
    const formValue = getValues();
    const [key] = Object.keys(formValue);
    const errors = await validateForm(formValue, key);

    if (!errors) {
      this.setState(prevState => {
        return {
          current: prevState.current + 1,
          data: { ...prevState.data, ...formValue } 
        };
      });
    }
  };

  onSubmit = () => {
    this.props.handleSubmit(this.state.data);
  };

  isButtonDisabled = (): boolean => {
    const { errors, getValues } = this.props;
    console.log('errors', errors)
    if (!getValues) return  false;

    const formValues = getValues();
    // There's only one field at a time, so this is safe for now.
    const [activeFieldKey] = Object.keys(formValues);

    return errors && !!errors[activeFieldKey];
  }

  render() {
    const { current } = this.state;
    const { children } = this.props;

    const items = React.Children.map(children || [], (child: any) => {
      const Element = child.type;
      return <Element {...child.props}/>;
    });

    return (
      <React.Fragment>
        <TypeFormHolder>
          <Transition
            native
            reset
            unique
            items={current}
            from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
            enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
            leave={{ opacity: 0, duration: 1000 }}
          >
            {index => styles => (
              <React.Fragment>
                <div/>
                <animated.div style={{ ...styles }} className="transition-div">
                  {items[index]}
                </animated.div>
              </React.Fragment>
            )}
          </Transition>

          {current < items.length - 1
            ? <Button onClick={this.handleOnClick} disabled={this.isButtonDisabled()} className="next-button">Next</Button>
            : <Button onClick={this.onSubmit} className="next-button" disabled={this.isButtonDisabled()}>Save</Button>
          }
        </TypeFormHolder>
      </React.Fragment>
    );
  }
}
