import styled from 'styled-components';
import { borderRadius, boxShadow } from '../../settings/style-util';

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, .12);
  margin: 0 0 30px;
  ${borderRadius('7px')};
  ${boxShadow('rgba(0, 0, 0, 0.06) 0 1px 0;')};

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 20px;
    ${'' /* margin: 0 10px 30px; */};
  }

  &.half {
    width: calc(50% - 34px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export { BoxWrapper };
