import styled from 'styled-components';

export const TypeFormHolder = styled.div`

  .transition-div {
    will-change: transform, opacity;
    width: 100%; 
    z-index: 10;
  }
  
  .next-button {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
