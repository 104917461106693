import React, { Component } from 'react';
import TextField from '@atlaskit/textfield';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';

export class AsyncValidationTextField extends Component<any> {
  handleChange = (e: any) => {
    const val = e.target.value;
    this.setState({ val });

    this.props.onChange(val);
    this.query$.next({ value: val });
  };
  query$ = new Subject();
  state = { val: '' };

  constructor(props: any) {
    super(props);

    this.query$.pipe(
      filter((val: any) => val),
      debounce(() => timer(200)),
      distinctUntilChanged(),
    ).subscribe(() => this.props.validate());

    this.state = {
      val: props.value || '',
    };
  }

  componentWillUnmount(): void {
    this.query$.unsubscribe();
  }

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <TextField
        onChange={this.handleChange}
        {...rest}
        value={this.state.val}
      />
    );
  }
}
