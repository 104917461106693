import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';
import { FieldDefinition, ColumnConfig } from 'components';
import { eInputType } from '../../components/DynamicForm';
import { copyExistingProps } from 'helpers/utility';
import { BaseEntity } from './BaseEntity';
import { eDataType } from './DataType';

export class WorkOrderCategory extends BaseEntity {
  static END_POINT = 'work/work_order_categories';
  static NAME = 'category';
  static CONTENT_TYPE_NAME = 'workordercategory';

  @IsNotEmpty()
  @MaxLength(200)
  name = '';

  @MaxLength(50)
  @IsOptional()
  code?: string = undefined;

  static FORM_FIELDS: FieldDefinition<WorkOrderCategory>[] = [
    { key: 'name', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
    { key: 'code', dataType: eDataType.STRING, inputType: eInputType.TEXT, isRequired: true },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true },
    { key: 'code' },
  ];

  constructor(obj: Partial<WorkOrderCategory>) {
    super();

    copyExistingProps(this, obj);
  }
}
