import React from 'react';
import { AsyncCreatableSelect } from '@atlaskit/select';
import { AsyncSelectConfig } from '../..';
import { makeRequest, METHODS, RequestConfig } from 'core/api/httpClient';
import { SelectOption } from '../../../uielements/select';

interface State {
  options?: any[];
  value?: string | SelectOption | SelectOption[] | null;
  currentPage: number;
  menuIsOpen: boolean | undefined,
}

interface Props extends AsyncSelectConfig {
  onChange: Function
  onBlur?: Function
}

interface SelectOption {
  id: string | number,
  name: string,
}

export class AsyncSelectField extends React.Component<Props, State> {
  static defaultsProps = {
    pageSize: 5,
  };

  allOptions: SelectOption[] = [];

  state = {
    currentPage: 0,
    options: [],
    value: null,
    inputValue: null,
    menuIsOpen: undefined,  // When undefined, it's controlled automatically by the component.
  };

  optionLabel = (option: { name: string; }) => option.name;
  optionValue = (option: { id: number; }) => option.id;

  findOption = (id: number, options: SelectOption[]) => {
    return options.find((option: SelectOption) => option.id === id);
  };

  filterOptions = (inputValue: string | null, allOptions: SelectOption[]) => {
    if (!inputValue || !inputValue.trim()) {
      return allOptions;
    }
    return allOptions.filter((option: SelectOption) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  loadOptions = (inputValue: string | null, callback?: any) => {
    const { endpoint = '' } = this.props;
    const { currentPage } = this.state;

    const limit = 10;
    const offset = limit * currentPage;
    const queryParams = { limit: limit.toString(), offset: offset.toString() };
    const requestConfig: RequestConfig = { url: endpoint, method: METHODS.GET, queryParams, shouldThrow: true };

    (makeRequest(requestConfig) as Promise<any>).then((options) => {
      if (this.state.currentPage > 0) {
        this.allOptions.push(...options.results);
        this.forceRenderDropdown();
      } else {
        this.allOptions = options.results;
      }

      let filteredOptions;

      // User typed something on the input.
      if (!inputValue || typeof inputValue === 'string') {
        filteredOptions = this.filterOptions(inputValue, this.allOptions);
      }
      // There's an option selected, don't filter.
      else {
        filteredOptions = this.allOptions;
      }

      if (callback) {
        callback(filteredOptions);
      }
      return filteredOptions;
    }).then(() => {
      const { value } = this.props;
      if (Array.isArray(value) && value.length > 0) {
        const newValue: SelectOption[] = [];

        value.forEach(option => {
          const selectionOption = this.findOption(option, this.allOptions);
          selectionOption && newValue.push(selectionOption);
        });

        this.setState({ value: newValue });
      } else if (this.props.value) {
        this.setState({ value: this.findOption(this.props.value, this.allOptions) });
      }
    });
  };

  handleMenuScrollToBottom = (e: any) => {
    // TODO: Don't make a request if the value hasn't changed or
    // if we are on the last page.
    this.setState((prevState) => {
      return { ...prevState, currentPage: prevState.currentPage + 1 };
    });
    this.loadOptions(this.state.value);
  };

  handleOnBlur = () => {
    const {
      onBlur = () => {
      },
    } = this.props;
    this.setState({ menuIsOpen: undefined });
    onBlur();
  };

  handleInputChange = (value: string) => {
    this.setState({ currentPage: 0 });
  };

  handleChange = (value: SelectOption | SelectOption[]) => {
    if (Array.isArray(value)) {
      const ids = value.map(option => option.id);
      this.setState({ value });
      this.props.onChange(ids);
    } else {
      this.setState({ value });

      this.props.onChange(value.id);
    }
  };

  forceRenderDropdown() {
    const originalValue = this.state.value;
    this.setState({ value: ' ' });
    this.setState({ value: originalValue });
  }

  render() {
    const { value, menuIsOpen } = this.state;
    const { optionLabel, optionValue, ...rest } = this.props;
    const getOptionLabel = optionLabel || this.optionLabel;
    const getOptionValue = optionValue || this.optionValue;

    return (
      <AsyncCreatableSelect
        isSearchable
        controlShouldRenderValue
        cacheOptions
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...rest}
        value={value}
        menuIsOpen={menuIsOpen}
        onMenuScrollToBottom={this.handleMenuScrollToBottom}
        loadOptions={this.loadOptions}
        defaultOptions
        onBlur={this.handleOnBlur}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
      />
    );
  }
}
