export type TargetType = 'sidebar' | 'modal'


export type PriorityType = 'high' | 'medium' | 'low'

export enum PRIORITY_COLORS {
  LOW = '#ebc034',
  MEDIUM = '#eb9634',
  HIGH = '#eb5c34'
}
