import React, { useState } from 'react';
import TextField from '@atlaskit/textfield';

  // TODO: Implement color picker.
export function ColorChooserField(props: any) {
  const { value = '', onChange, ...rest } = props;
  const [text, setText] = useState('');

  const handleChange = (e: any) => {
    const val = e.target.value;
    setText(val);
    const trimmedVal = val ? val.trim() : val;
    onChange(trimmedVal);
  };

  React.useEffect(() => setText(value), [value]);
  return (
    <TextField
      onChange={handleChange}
      {...rest}
      value={text || ''}
    />
  );
}
