/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import registerServiceWorker from './registerServiceWorker';
import './antd-override.css';
import './global.css';
import './helpers/utility';

ReactDOM.render(<DashApp />, document.getElementById('root'));

// Hot Module Replacement API
if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  module.hot.accept('./dashApp.js', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./dashApp').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
registerServiceWorker();
