/* eslint-disable @typescript-eslint/camelcase */
import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';
import { ColumnConfig, FieldDefinition } from 'components';
import { copyExistingProps } from 'helpers/utility';
import { eInputType } from '../../components/DynamicForm';
import { eDataType } from '.';
import { BaseEntity } from './BaseEntity';

export class Asset extends BaseEntity {
  static NAME = 'asset';
  static CONTENT_TYPE_NAME = 'asset';
  static END_POINT = 'assets/assets';

  @IsNotEmpty()
  @MaxLength(50)
  public chasis_id: string = '';

  @IsNotEmpty()
  @MaxLength(50)
  public motor_id: string = '';

  @IsNotEmpty()
  @MaxLength(50)
  public other_id: string = '';

  public photo: string | null = null;

  // public files: any[];

  public usage_distance: number = 0;

  public usage_hours: number = 0;


  @MaxLength(12)
  @IsOptional()
  public price?: number = undefined;

  @IsNotEmpty()
  public warranty_expiration_date: string = '';

  public location: number | null = null;

  @IsNotEmpty()
  asset_category: number | null = null;

  asset_maker: number | null = null;

  asset_model: number | null = null;

  public static FORM_FIELDS: FieldDefinition<Asset>[] = [
    { key: 'chasis_id', dataType: eDataType.NUMBER, inputType: eInputType.TEXT, isRequired: true },
    { key: 'motor_id', dataType: eDataType.NUMBER, inputType: eInputType.TEXT, isRequired: true },
    { key: 'other_id', dataType: eDataType.NUMBER, inputType: eInputType.TEXT, isRequired: true },
    { key: 'usage_distance', dataType: eDataType.NUMBER, inputType: eInputType.NUMBER },
    { key: 'usage_hours', dataType: eDataType.NUMBER, inputType: eInputType.NUMBER },
    { key: 'price', dataType: eDataType.DECIMAL, inputType: eInputType.NUMBER },
    {
      key: 'warranty_expiration_date',
      label: 'Warranty Expiration',
      dataType: eDataType.DATE,
      inputType: eInputType.DATE,
      isRequired: true,
    },
    {
      key: 'location',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: {
        endpoint: 'assets/locations',
        optionLabel: ({ city_name, address }: any) => `${city_name}, ${address}`,
      },
    },
    {
      key: 'asset_category',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'assets/asset_categories' },
      isRequired: true,
    },
    {
      key: 'asset_maker',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'assets/asset_makers' },
    },
    {
      key: 'asset_model',
      dataType: eDataType.STRING,
      inputType: eInputType.ASYNC_SELECT,
      fieldOptions: { endpoint: 'assets/asset_models' },
    },
  ];

  static columnConfig: ColumnConfig[] = [
    { key: 'name', isClickable: true, orderingFields: ['asset_maker__name', 'asset_model__name', 'asset_category__name'] },
    { key: 'id' },
    { key: 'location_address' },
    { key: 'warranty_expiration_date', label: 'Warranty Expiration' },
    { key: 'asset_category', shouldHide: true, isChecked: false },
    { key: 'asset_maker', shouldHide: true },
    { key: 'asset_model', shouldHide: true },
    { key: 'maintenance_status', accessor: 'maintenance_status.general' },
  ];

  constructor(obj: Partial<Asset>) {
    super();

    copyExistingProps(this, obj);
  }
}
