import { Radio } from 'antd';
import styled from 'styled-components';

export interface RadioButtonProps {
  color?: string
  borderColor?: string
  isChecked?: boolean
  textColor?: string
}

export const RadioButton = styled(Radio.Button) <RadioButtonProps>`
  background-color: ${props => props.isChecked ? props.color : ''} !important;
  border-color: ${props => props.isChecked ? (props.borderColor || props.color) : ''} !important;
  box-shadow: 0 0 0 0 !important;
  
  :hover {
   color: ${props => props.color} !important;
  }
  
  span {
    color: ${props => props.isChecked ? props.textColor : ''};
  }
`;
