import firebase from "firebase/app";
import "firebase/auth";
import { BehaviorSubject } from 'rxjs';

  // Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCW9AcktpdA9apiLs2Bw2MR27c8pj0jwyo",
    authDomain: "timemute.firebaseapp.com",
    databaseURL: "https://timemute.firebaseio.com",
    projectId: "timemute",
    storageBucket: "timemute.appspot.com",
    messagingSenderId: "985350902344",
    appId: "1:985350902344:web:c5e9247ca56741f72ebe7d",
    measurementId: "G-V6B5KFF1HP"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export const fireauth = firebase.auth();
export type Provider = 'google';
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const providers: Record<Provider, firebase.auth.GoogleAuthProvider> = {
  'google': googleProvider,
};

enum UserStatus {
  Unknown,
  SignedIn,
  SignedOut,
}

export class UserState {
  readonly status: UserStatus;
  readonly user: firebase.User | undefined | null;
  constructor(user: firebase.User | undefined | null) {
    this.status =
      // eslint-disable-next-line no-nested-ternary
      user === undefined
        ? UserStatus.Unknown
        : user === null
        ? UserStatus.SignedOut
        : UserStatus.SignedIn;
    this.user = user;
  }
}

export const fbUserAuthState$ = new BehaviorSubject<UserState>(new UserState(undefined));
export const idToken$ = new BehaviorSubject<string>('');

/**
 * Adds an observer for changes to the user's sign-in state.
 * Only triggered on sign-in or sign-out.
 */
 firebase.auth().onAuthStateChanged(async (user: firebase.User | null) => {
  fbUserAuthState$.next(new UserState(user));
});

/**
 * Adds an observer for changes to the signed-in user's ID token,
 * which includes sign-in, sign-out, and token refresh event
 */
firebase.auth().onIdTokenChanged(async (user: firebase.User | null) => {
  const idToken = user ? await user.getIdToken() : '';
  idToken$.next(idToken);

  fbUserAuthState$.next(new UserState(user));
});


/**
 * Auth client error codes and their corresponding messages overriding
 * the ones that Firebase returns with ours.
 * 
 * Full list here:
 * https://github.com/firebase/firebase-admin-node/blob/master/src/utils/error.ts
 */
export class AuthClientErrorCode {
  public static BILLING_NOT_ENABLED = {
    code: 'billing-not-enabled',
    message: 'Feature requires billing to be enabled.',
  };
  public static EMAIL_ALREADY_EXISTS = {
    code: 'email-already-exists',
    message: 'This email is already in use. Forgot your password?',
  };
  public static EMAIL_ALREADY_IN_USE = {
    code: 'email-already-in-use',
    message: 'This email is already in use. Forgot your password?',
  };
  public static INVALID_EMAIL = {
    code: 'invalid-email',
    message: 'Invalid email address.',
  };
  public static INVALID_PASSWORD = {
    code: 'invalid-password',
    message: 'The password must be a string with at least 6 characters.',
  };
  public static WEAK_PASSWORD = {
    code: 'weak-password',
    message: 'The password must be a string with at least 6 characters.',
  };
  public static WRONG_PASSWORD = {
    code: 'wrong-password',
    message: 'Entered password is invalid.',
  };
  public static MISSING_EMAIL = {
    code: 'missing-email',
    message: 'The email is required for the specified action.',
  };
  public static UNVERIFIED_EMAIL = {
    code: 'unverified-email',
    message: 'A verified email is required for the specified action.',
  };
  public static USER_NOT_FOUND = {
    code: 'user-not-found',
    message: 'A user with that email was not found.',
  };
  public static NOT_FOUND = {
    code: 'not-found',
    message: 'The requested resource was not found.',
  };

  static getUserFriendlyMessage(error: {code: string, message: string} | any): string {
    if (error.code) {
      const formattedErrorCode = error.code.replace('auth/', '');

      for (let prop of Object.getOwnPropertyNames(AuthClientErrorCode)) {
        // @ts-ignore
        const friendlyError = AuthClientErrorCode[prop];
        if (friendlyError.code === formattedErrorCode) {
          return friendlyError.message;
        }
      }
    }
    return 'There was an error. Please try again later or contact support.';
  }
}