import { getDefaultPath } from '../../helpers/urlSync';
import actions, { getView } from './actions';
import {
  addTablePreference,
  editTablePreference,
  formatContentType,
  removeTablePreference,
} from './functions';

const preKeys = getDefaultPath();

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  isLoading: true,
  notificationCount: 0,
  contentTypes: [],
  userPreference: {},
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLAPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed };
    case actions.COLLAPSE_OPEN_DRAWER:
      return { ...state, openDrawer: !state.openDrawer };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height: height,
        };
      }
      break;
    case actions.TOGGLE_LOADING:
      return { ...state, isLoading: action.isLoading };
    case actions.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case actions.CHANGE_CURRENT:
      return { ...state, current: action.current };
    case actions.CLOSE_ALL:
      return { ...state, current: [], openKeys: [] };
    case actions.FETCH_CONTENT_TYPES_SUCCESS:
      return { ...state, contentTypes: formatContentType(action.contentTypes) };
    case actions.GET_USER_PREFERENCES_SUCCESS:
      return { ...state, userPreference: action.userPreference };
    case actions.ADD_USER_TABLE_PREFERENCE:
      return { ...state, userPreference: addTablePreference(action.tablePreference, state.userPreference) };
    case actions.EDIT_USER_TABLE_PREFERENCE:
      return { ...state, userPreference: editTablePreference(action.tablePreference, state.userPreference) };
    case actions.DELETE_USER_TABLE_PREFERENCE:
      return { ...state, userPreference: removeTablePreference(action.tablePreference, state.userPreference) };
    case actions.FETCH_UNREAD_NOTIFICATIONS_SUCCESS:
      return { ...state, notificationCount: action.notificationCount };
    default:
      return state;
  }
  return state;
}
