import { TENANT_UUID } from './constants';


export function deleteTenantUUID() {
  localStorage.removeItem(TENANT_UUID);
}

export function getTenantUUID(): string | null {
  return localStorage.getItem(TENANT_UUID) || '';
}

export function setTenantUUID(uuid: string): void {
  localStorage.setItem(TENANT_UUID, uuid);
}
