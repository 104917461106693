import React from 'react';
import { Input } from 'antd';
import { Subject, timer, Subscription } from 'rxjs';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';

interface Props {
  onNewData: (data: any) => void
}

let onChangeQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;

export class SearchInput extends React.Component<Props> {
  subscription: Subscription;

  constructor(props: Props) {
    super(props);
    const query$ = new Subject();
    onChangeQuery = (event: any) => query$.next({ query: event.target.value });
    const queryForFetch$ = query$.pipe(
      filter((query: any) => query),
      debounce(() => timer(400)),
      distinctUntilChanged(),
    );

    this.subscription = queryForFetch$.subscribe((newData: any) =>
      this.props.onNewData(newData.query),
    );
  }

  componentWillUnmount(): void {
    this.subscription.unsubscribe();
  }

  render() {
    return (
      <Input.Search
        className="searchBar"
        onChange={onChangeQuery}
        placeholder="Search..."
      />
    );
  }
}
