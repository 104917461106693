import React, { useState } from 'react';
import { Select, Space, Button } from 'antd';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import DatePicker from 'components/uielements/datePicker';
import dayjs, { Dayjs } from 'dayjs';
import { SwitchableDatePickerFieldWrapper } from './SwitchableDatePickerField.style';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Option } = Select;

export type PickerType = SwitchableDatePickerFieldProps['picker'];

export type TimeUnit = 'day' | 'week' | 'month' | 'year';

class Choice<T, U> {
  constructor(
    public value: T,
    public display: U) {
  }
}

export class TimeUnits {
  static readonly day = new Choice<TimeUnit, string>('day', 'Day');
  static readonly week = new Choice<TimeUnit, string>('week', 'Week');
  static readonly month = new Choice<TimeUnit, string>('month', 'Month');
  static readonly year = new Choice<TimeUnit, string>('year', 'Year');
}

export interface SwitchableDatePickerFieldProps extends Omit<PickerProps<Dayjs>, 'onChange'> {
  disabledLeftButton?: (currentValue: Dayjs | null, selectedUnit: TimeUnit) => boolean,
  disabledRightButton?: (currentValue: Dayjs | null, selectedUnit: TimeUnit) => boolean,
  onChange?: (
    value: Dayjs | null,
    dateString: string,
    selectedUnit: TimeUnit
  ) => void,
  onPickerTypeChange?: (selectedUnit: TimeUnit) => void,
}

interface PickerWithTypeProps extends Omit<
  SwitchableDatePickerFieldProps,
  'disabledLeftButton' | 'disabledRightButton' | 'onPickerTypeChange'
> {
}

export function getUnitFromPickerType(type: PickerType): TimeUnit {
  const unitMap = {
    date: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
  };

  const unit = unitMap[type as string];
  if (!unit) {
    throw Error(`Unit for picker type ${type} not supported`);
  }

  return unit;
}

function PickerWithType(props: PickerWithTypeProps) {
  const { picker, value, onChange, ...rest } = props;

  const handleChange = (val: Dayjs | null, dateString: string) => {
    if (value && onChange) {
      const unit = getUnitFromPickerType(picker);
      onChange(val, dateString, unit);
    }
  };

  return <DatePicker {...rest} picker={picker} onChange={handleChange} value={value} />;
}

export function SwitchableDatePickerField(props: SwitchableDatePickerFieldProps) {
  const {
    picker,
    value,
    onChange,
    onPickerTypeChange,
    disabledLeftButton,
    disabledRightButton,
    style,
    ...rest
  } = props;

  const [pickerType, setPickerType] = useState<PickerType>(picker || 'date');
  const [pickerValue, setPickerValue] = useState<Dayjs | null>(value || dayjs());
  const now = dayjs();

  const handlePickerTypeChange = (type: PickerType) => {
    setPickerType(type);
    if (onPickerTypeChange) {
      const unit = getUnitFromPickerType(type);

      // Reset to today.
      setPickerValue(dayjs());
      onPickerTypeChange(unit);
    }
  };

  const handleChange = (val: Dayjs | null, dateString: string) => {
    setPickerValue(val);
    if (onChange) {
      const unit = getUnitFromPickerType(pickerType);
      onChange(val, dateString, unit);
    }
  };

  const handleLeftButtonClick = () => {
    if (pickerValue) {
      const unit = getUnitFromPickerType(pickerType);
      const newValue = pickerValue.subtract(1, unit);
      handleChange(newValue, newValue.toISOString());
    }
  };

  const handleRightButtonClick = () => {
    if (pickerValue) {
      const unit = getUnitFromPickerType(pickerType);
      const newValue = pickerValue.add(1, unit);
      handleChange(newValue, newValue.toISOString());
    }
  };

  return (
    <SwitchableDatePickerFieldWrapper>
      <Space size="small" style={style}>
        <Button icon={<LeftOutlined />}
          disabled={disabledLeftButton && disabledLeftButton(pickerValue, getUnitFromPickerType(pickerType))}
          onClick={handleLeftButtonClick}
        />

        <Space size={2}>
          <Select value={pickerType} onChange={handlePickerTypeChange}>
            <Option value="date">Day</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="year">Year</Option>
          </Select>

          <PickerWithType
            {...rest}
            value={pickerValue}
            onChange={handleChange}
            picker={pickerType}
          />
        </Space>

        <Button icon={<RightOutlined />}
          disabled={disabledRightButton && disabledRightButton(pickerValue, getUnitFromPickerType(pickerType))}
          onClick={handleRightButtonClick}
        />
      </Space>
    </SwitchableDatePickerFieldWrapper>
  );
}
