import Form from '@atlaskit/form';
import React from 'react';
import { Button } from 'antd';
import { saveEntity } from 'core/api';
import { validatebyClassAsync } from 'helpers/utility';
import { FormWrapper } from './AutoSubmitForm.style';
import { debounce } from 'lodash';
import { showNotification } from 'components/Notification';


interface Data {
  id?: number | undefined,
  [index: string]: any
}

interface State {
  oldData: Data,
  errors: Record<string, any>,
}

interface Props<T> {
  /** Class to instantiate data upon saving. */
  classConstructor: Constructor<any>,
  /** Class to validate data against. If undefined, classConstructor is used. */
  validationClass?: Constructor<any>,
  showSubmitButton?: boolean,
  entityId?: number,
  endpoint?: string,
  onSubmit?: (data: Record<string, any>) => Promise<T>,
  onSubmitSuccess?: (updatedData: T) => void,
  onSubmitFail?: (error: any) => void,
  submitButtonText?: string,
}

export class AutoSubmitForm<T> extends React.Component<Props<T>, State> {
  static defaultProps = {
    showSubmitButton: false,
    submitButtonText: 'Save',
    onSubmitSuccess: (updatedData: Record<string, any>) => undefined
  };

  state = {
    oldData: {},
    errors: [],
  };

  componentDidMount() {
    const { onSubmit, entityId, endpoint} = this.props;
    if (onSubmit) {
      if (entityId || endpoint) {
        throw Error(
          'When "onSubmit" prop is passed, neiter "entityId" nor "endpoint" are used, then cannot be passed.'
        );
      }
    }
  }

  handleValidate = async (data: Data) => {
    const { validationClass, classConstructor } = this.props;
    const validationRules = validationClass || classConstructor;
    const errors = await validatebyClassAsync(data, validationRules);

    this.setState({ errors });
    return !!Object.keys(errors).length;
  };

  handleSubmit = async (data: Record<string, any>) => {
    const hasErrors = await this.handleValidate(data);

    if (hasErrors) {
      return;
    }

    const {
      endpoint,
      showSubmitButton,
      entityId: id,
      onSubmit,
      onSubmitSuccess,
      onSubmitFail,
      classConstructor } = this.props;

    try {
      const updatedData = onSubmit
        ? await onSubmit(data)
        : await saveEntity<T>(endpoint as string, { id, ...data }, classConstructor);
        
      if (onSubmitSuccess) {
        onSubmitSuccess(updatedData);
      }

      // This just to make the user experience better.
      if (showSubmitButton) {
        showNotification('success', { message: 'Saved' });
      }
    } catch (e) {
      console.warn(e);
      showNotification('error', { message: 'something failed' });

      if (onSubmitFail) {
        onSubmitFail(e);
      }
    }
  };

  validateField = (getValues: Function) => {
    const data = getValues();
    this.handleValidate(data);
  };

  saveField = async (getValues: Function, caller: any) => {
    const target: string = caller.currentTarget.name;
    const data = getValues();
    const { oldData }: { oldData: Record<string, any> } = this.state;

    if (oldData[target] !== data[target]) {
      this.setState({ oldData: data });

      const hasErrors = await this.handleValidate(data);

      if (!hasErrors && !this.props.showSubmitButton) {
        this.handleSubmit(data);
      }
    }
  };

  render() {
    const { showSubmitButton, submitButtonText } = this.props;
    const { errors } = this.state;
    return (
      <FormWrapper>
        <Form onSubmit={this.handleSubmit}>
          {({ formProps, getValues }: any) => {
            const { children } = this.props as any;
            if (Object.keys(this.state.oldData).length === 0) this.setState({ oldData: getValues() });
            return <form {...formProps}>
              {/* Pass params to children() so they can access methods in this class. */}
              {children && children(
                this.saveField.bind(null, getValues),
                errors,
                debounce(this.validateField.bind(null, getValues), 300)
              )}
              {showSubmitButton &&
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                disabled={!!Object.keys(errors).length}
              >
                {submitButtonText}
              </Button>}
            </form>;
          }}
        </Form>
      </FormWrapper>
    );
  }
}
