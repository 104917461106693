import React from 'react';
import { Button as AntButton, ButtonProps } from 'antd';
import ButtonStyle from './button.style';


export const Button = ButtonStyle((props: ButtonProps) => (
  <AntButton {...props}>
    {props.children}
  </AntButton>
));
