import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getAuthUser, updateAuthUser as updateAuthUserFn, loginUserPassword, logoutAndClearToken, signUpUserPassword, refreshAuthUserData } from 'core/api/account';
import { deleteIdToken, setIdToken } from 'helpers/store';
import { actions, authActions, LoginWithIdTokenRequest, LoginRequest, LoginSuccess, SignUpRequest, UpdateAuthUser } from './actions';
import appActions from '../app/actions';


/**
 * Logs in the user on firebase, then on Django.
 */
export function* loginWithUserAndPassword() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ email, password }: LoginRequest) {
    try {
      const idToken = yield call(loginUserPassword, email, password);
      if (idToken) {
        const authUser = yield call(getAuthUser, idToken);
        yield put(authActions.loginSuccess(idToken, authUser));
      } else {
        yield put(authActions.loginFail());
      }
    } catch (error) {
      yield put(authActions.loginFail());
    }
  });
}

/**
 * Logs in the user in Django with a given tokenId, which can be previously
 * obtained after authenticating with a provider (ie. Google), for example.
 */
export function* loginWithIdToken() {
  yield takeEvery(actions.LOGIN_WITH_ID_TOKEN_REQUEST, function* ({ idToken }: LoginWithIdTokenRequest) {
    try {
      if (idToken) {
        const authUser = yield call(getAuthUser, idToken);
        yield put(authActions.loginSuccess(idToken, authUser));
      } else {
        yield put(authActions.loginFail());
      }
    } catch (error) {
      yield put(authActions.loginFail());
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield logoutAndClearToken();
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ idToken }: LoginSuccess) {
    yield setIdToken(idToken);
  });
}

export function* loginFail() {
  yield takeEvery(actions.LOGIN_FAIL, function* () {
    yield put(appActions.toggleLoading(false));
    yield deleteIdToken();
  });
}

export function* signUp() {
  yield takeEvery(actions.SIGN_UP_REQUEST, function* ({ email, password }: SignUpRequest) {
    try {
      const firebaseUser = yield call(signUpUserPassword, email, password);
      if (firebaseUser) {
        yield put(authActions.signUpSuccess());
        yield put(authActions.loginRequest(email, password));
      } else {
        yield put(authActions.signUpFail());
      }
    } catch (error) {
      yield put(authActions.signUpFail());
    }
  });
}

export function* checkLoggedStatus() {
  yield takeEvery(actions.CHECK_LOGGED_STATUS, function* () {
    const authUserData = yield call(refreshAuthUserData);
    if (!authUserData) {
      yield put(authActions.loginFail());
      return;
    }
    const { idToken } = authUserData;

    try {
      const authUser = yield call(getAuthUser, idToken);
      yield put(authActions.loginSuccess(idToken, authUser));
    } catch (e) {
      yield put(authActions.loginFail());
    }
  });
}

export function* updateAuthUser() {
  yield takeEvery(actions.UPDATE_AUTH_USER, function* ({ authUser, currentPassword, onSuccess, onFail }: UpdateAuthUser) {
    try {
      const updatedAuthUser = yield call(updateAuthUserFn, authUser, currentPassword, onSuccess, onFail);
      if (updatedAuthUser) {
        yield put(authActions.updateAuthUserSuccess(updatedAuthUser));
      } else {
        yield put(authActions.updateAuthUserError());
      }
    } catch (error) {
      yield put(authActions.updateAuthUserError());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginWithUserAndPassword),
    fork(loginWithIdToken),
    fork(logout),
    fork(loginSuccess),
    fork(loginFail),
    fork(signUp),
    fork(checkLoggedStatus),
    fork(updateAuthUser),
  ]);
}
