import axios from 'axios';
import { makeRequest, METHODS, SERVER_URL } from 'core/api/httpClient';
import { fireauth, AuthClientErrorCode, fbUserAuthState$ } from 'settings/firebase';
import { deleteIdToken, deleteTenantUUID, getIdToken, getTenantUUID, setIdToken, setTenantUUID } from 'helpers/store';
import { toInstance } from 'helpers/utility';
import { showNotification } from 'components/Notification';
import { saveEntity } from './form';
import { User } from 'core/entities';
import firebase from 'firebase';


const CURRENT_USER_URL = [SERVER_URL, 'auths', 'current_user', ''].join('/');

export async function loginUserPassword(email: string, password: string): Promise<string | void> {
  try {
    await fireauth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await fireauth.signInWithEmailAndPassword(email, password);
    const fbUser = fbUserAuthState$.value.user;
    if (fbUser) {
      const idToken = await fbUser.getIdToken(true);
      return idToken;
    }
  } catch (error) {
    const message = AuthClientErrorCode.getUserFriendlyMessage(error);
    showNotification('error', { message });
  }
}

export async function signUpUserPassword(email: string, password: string): Promise<firebase.User | void> {
  try {
    const userCredential = await fireauth.createUserWithEmailAndPassword(email, password);
    if (userCredential && userCredential.user) {
      return userCredential.user;
    }
  } catch (error) {
    const message = AuthClientErrorCode.getUserFriendlyMessage(error);
    showNotification('error', { message });
  }
}


export async function logoutAndClearToken(): Promise<void> {
  deleteIdToken();
  await fireauth.signOut();
}

/**
 * Requests a password reset email on firebase.
 */
export async function resetPassword(email: string): Promise<boolean> {
  try {
    const homeURL = window.location.href.replace(window.location.pathname, '');
    await fireauth.sendPasswordResetEmail(email, { url: homeURL });
    return true;
  } catch (error) {
    const message = AuthClientErrorCode.getUserFriendlyMessage(error);
    showNotification('error', { message });
    return false;
  }
}

export async function forgotPasswordConfirm(data: {}) {
  // return axios.post(`${FORGOT_PASSWORD_URL}confirm/`, data);
}

/**
 * Updates the user password on firebase. 
 */
export async function changePassword(email: string, currentPassword: string, newPassword: string,
  onSuccess: () => void, onError: (error: any) => void): Promise<void> {
  const fbUser = fbUserAuthState$.value.user;
  if (!fbUser) {
    if (onError) onError('No current user found.');
    return;
  };

  try {
    await fireauth.signInWithEmailAndPassword(email, currentPassword);
  } catch (error) {
    const message = error.code === 'auth/wrong-password'
      ? 'Current password is invalid.'
      : AuthClientErrorCode.getUserFriendlyMessage(error);

    showNotification('error', { message });
    onError(error);
    return;
  }

  return fbUser.updatePassword(newPassword)
    .then(onSuccess, (error) => {
      const message = AuthClientErrorCode.getUserFriendlyMessage(error);
      showNotification('error', { message });
      onError(error);
    });
}

/**
 * Returns the currently authenticated user, based on a passed id token.
 */
export async function getAuthUser(idToken: string): Promise<User | null> {
  try {
    const authUserResponse = await axios.get<User>(CURRENT_USER_URL, {
      headers: { 'Authorization': `Bearer ${idToken}` },
    });

    const authUser = toInstance(authUserResponse.data, User);
    return authUser;
  } catch (err) {
    const message = 'There was an error. Please try again later or contact support.';
    showNotification('error', { message });
    return null;
  }
}

/**
 * Updates the currently authenticated user, both on the backend and on firebase,
 * if needed (ie. if the "email" property is passed within the user data).
 */
export async function updateAuthUser(
  authUser: Partial<User & { id: number }>,
  currentPassword?: string,
  onSuccess?: (updatedAuthUser: User) => void,
  onError?: (error: any) => void
): Promise<User | void> {

  const fbUser = fbUserAuthState$.value.user;
  if (!fbUser) {
    if (onError) onError('No current user found.');
    return;
  };

  if (!authUser.id) {
    throw Error('"authUser" must contain an "id" in order to be updated.');
  }

  // If we want to update the email, it must be treated specially.
  if (authUser.email) {
    const newEmail = authUser.email;
    const currentEmail = fbUser.email as string;

    if (newEmail !== currentEmail) {
      if (!currentPassword) {
        throw Error(
          'Must pass "currentPassword" param when the "email" property is included in the update.'
        );
      }

      // Update the email on Firebase.
      try {
        // Make sure we confirm their password before allowing the change.
        await fireauth.signInWithEmailAndPassword(currentEmail, currentPassword);
        await fbUser.updateEmail(authUser.email);
      } catch (error) {
        const message = AuthClientErrorCode.getUserFriendlyMessage(error);
        showNotification('error', { message });
        if (onError) {
          onError(error);
        }
        return;
      }
    }
  }

  try {
    const updatedAuthUser = await saveEntity<User>(User.END_POINT, authUser, User);
    showNotification('success', { message: 'Saved successfully' });
    if (onSuccess) {
      onSuccess(updatedAuthUser);
    }
    return updatedAuthUser;
  } catch (error) {
    if (authUser.email) {
      // If we get here, we could get the emails out of sync, (updated on Firebase, but not on Django).
      // TODO : Log this on Sentry.
    }
    showNotification('error', { message: 'There was an error. Please contact support.' });
    console.error('Error when updating email on server', error);
    if (onError) {
      onError(error);
    }
  }
}

export async function refreshAuthUserData(
  idTokenToCheck?: string,
  force = false
): Promise<{ idToken: string, tenantUUID: string } | null> {

  let idToken = idTokenToCheck || getIdToken();
  let tenantUUID = getTenantUUID();

  if (!idToken || force) {
    const fbUser = fbUserAuthState$.value.user;
    if (fbUser) {
      idToken = await fbUser.getIdToken(true);
    } else {
      // There's no way to get the token.
      clearAuthUserData();
      return null;
    }
  }

  if (!tenantUUID || force) {
    tenantUUID = await getAuthUserTenantUUID(idToken);
  }

  setIdToken(idToken);
  setTenantUUID(tenantUUID);

  return { idToken, tenantUUID };
}

export function clearAuthUserData(): void {
  deleteTenantUUID();
  deleteIdToken();
}

export async function getAuthUserTenantUUID(idToken: string): Promise<string> {
  // For now, we'll assume users only have one Workspace. In the future, we
  // might want to show a "Select a Workspace" screen, before proceeding
  // if the user belongs to more than one workspace.
  const authUser = await getAuthUser(idToken) as User;
  const firstWorkspace = authUser.workspaces[0];
  if (!firstWorkspace) {
    console.error('This account does not belong to any workspaces. Please contact support.');
  }

  return firstWorkspace.tenant_uuid;
}

export async function confirmEmail(uid: string, token: string) {
  return makeRequest({ url: `auths/activate/${uid}/${token}`, method: METHODS.GET });
}
